import { User } from "@/modules/strapi-sdk/lib/interfaces";
import { strapiInstance } from "@/providers/StrapiProvider";
import { selector, selectorFamily } from "recoil";

type UserType = NonNullable<User["type"]>;

export const userTypeLabel: Record<UserType, string> = {
  customer: "Client",
  admin: "Administrateur",
  cm: "Comunity M.",
  commercial: "Commercial",
};

export const userTypeOptions = Object.entries(userTypeLabel).map(
  ([value, label]) => ({ value, label })
);

export const usersList = selector({
  key: " users",
  get: async () => {
    const users = (await strapiInstance.find("me/users", {
      populate: {
        avatar: true,
      },
    })) as unknown;

    return users as User[];
  },
});

export const userById = selectorFamily({
  key: "user-by-id",
  get: (id: number | boolean) => async () => {
    if (typeof id !== "number") return undefined;

    return (
      await strapiInstance.find("me/users", {
        filters: {
          id,
        },
        populate: {
          avatar: true,
        },
      })
    )?.data?.[0];
  },
});
