import React, {
  PropsWithChildren,
  useEffect,
  useCallback,
  useMemo,
  useState,
} from "react";
import { useStrapi } from "@/providers/StrapiProvider";
import {
  useRecoilRefresher_UNSTABLE,
  useRecoilState,
  useRecoilValue,
} from "recoil";
import { authState } from "@/store/auth";
import { useAuth } from "@/hooks/auth";
import LoginPage from "../features/auth/LoginPage";
import Menu from "./Menu";
import BreadCrumb from "./BreadCrumb";
import { Drawer, LinearProgress } from "@mui/material";
import { notificationModal, notificationsSelector } from "@/store/notification";
import Notifications from "./Notifications";
import AdminLayout from "./AdminLayout";
import { ContainerProps, useColorMode } from "@repleek/mui";
import Sidebar from "./Sidebar";
import SelectAccount from "../features/auth/SelectAccount";

type LayoutProps = {
  mode: "LOGGED_IN" | "LOGGED_OUT" | "ADMIN" | "CUSTOMER";
  title?: string;
  redirect?: string;
  body?: Omit<ContainerProps, "children">;
  topBar?: Omit<ContainerProps, "children">;
};

const Layout: React.FC<PropsWithChildren<LayoutProps>> = (props) => {
  const {
    title = "Pistache Report",
    mode,
    redirect,
    body,
    topBar,
    children,
  } = props;

  const [strapi, { loading }] = useStrapi();
  const user = useRecoilValue(authState);
  const autorized = useAuth({ mode, redirect });
  const [notification, setNotification] = useRecoilState(notificationModal);
  const [sidebarDrawer, setSidebarDrawer] = useState(false);
  const refreshNotifications = useRecoilRefresher_UNSTABLE(
    notificationsSelector
  );

  const { mode: theme, setMode } = useColorMode();

  const userName = useMemo(
    () => ({
      name:
        [user?.firstName, user?.lastName].filter((v) => v).join(" ") ||
        user?.email,
      initial: [user?.firstName?.[0], user?.lastName?.[0]]
        .filter((v) => v)
        .join(""),
      src: user?.avatar?.url,
    }),
    [user]
  );

  const openNotification = useCallback(() => {
    refreshNotifications();
    setNotification(true);
  }, [refreshNotifications, setNotification]);

  const logout = useCallback(() => {
    user && strapi?.logout();
  }, [user, strapi]);

  const sidebar = useMemo(
    () => (
      <Sidebar
        user={{ hidden: !user }}
        avatar={{ initial: userName.initial, src: userName.src }}
        name={{ text: userName.name }}
        menu={{ children: user && <Menu type={user.type} /> }}
        logout={{ onClick: logout, hidden: !user }}
        login={{ hidden: !!user }}
        logoLigth={{ hidden: theme === "dark" }}
        logoDark={{ hidden: theme === "light" }}
      />
    ),
    [logout, theme, user, userName.initial, userName.name, userName.src]
  );

  useEffect(() => {
    document.title = title;
  }, [title]);

  return (
    <>
      {loading && (
        <LinearProgress
          sx={{
            position: "absolute",
            top: 0,
            left: 0,
            width: "100%",
            zIndex: 200,
          }}
          color="secondary"
        />
      )}

      <AdminLayout
        body={{
          ...body,
          children: autorized ? children : <LoginPage />,
        }}
        topBar={{ ...topBar }}
        breadcrumb={{ children: <BreadCrumb /> }}
        notification={{
          onClick: openNotification,
          hidden: !user?.type || !["admin", "cm"].includes(user.type),
        }}
        light={{ onClick: () => setMode("dark"), hidden: theme === "dark" }}
        dark={{ onClick: () => setMode("light"), hidden: theme === "light" }}
        sidebar={{
          hidden: !user,
          children: sidebar,
        }}
        menu={{
          onClick: () => setSidebarDrawer(true),
          hidden: !user,
        }}
        userAccountSelect={{
          hidden: user?.type && !["customer", "commercial"].includes(user.type),
          children: <SelectAccount />,
        }}
      />

      <Drawer
        open={notification}
        anchor="right"
        onClose={() => setNotification(false)}
        PaperProps={{
          sx: {
            height: "calc(100% - 16px)",
            top: "8px",
            right: "12px",
            borderRadius: "18px",
          },
        }}>
        <Notifications />
      </Drawer>

      <Drawer
        open={!!user && sidebarDrawer}
        anchor="right"
        onClose={() => setSidebarDrawer(false)}
        PaperProps={{
          sx: {
            height: "calc(100% - 16px)",
            top: "8px",
            right: "12px",
            borderRadius: "18px",
          },
        }}>
        {sidebar}
      </Drawer>
    </>
  );
};

export default Layout;
export * from "./modals";
