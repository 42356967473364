import React, { useMemo } from "react";
import { Avatar, Badge, Tooltip } from "@mui/material";
import { useWatch } from "react-hook-form";
import {
  HootsuiteSocialProfile,
  SocialAccount,
} from "@/modules/strapi-sdk/lib/interfaces";

type AvatarBadgeProps = {
  type: SocialAccount["type"];
  onClick: () => void;
};

type SocialBadgeProps = {
  onOpenHootsuite: (type: HootsuiteSocialProfile["type"]) => void;
};

const SocialBadge: React.FC<SocialBadgeProps> = (props) => {
  const { onOpenHootsuite } = props;

  const configuration = useWatch({
    name: "configuration",
  }) as Record<SocialAccount["type"], boolean>;

  const avatars = useMemo(() => {
    return Object.entries(configuration).map(
      ([type, value]) =>
        typeof value === "boolean" &&
        value && (
          <AvatarBadge
            key={type}
            type={type as SocialAccount["type"]}
            onClick={() =>
              onOpenHootsuite(type as HootsuiteSocialProfile["type"])
            }
          />
        )
    );
  }, [configuration, onOpenHootsuite]);

  return <>{avatars}</>;
};

const AvatarBadge: React.FC<AvatarBadgeProps> = (props) => {
  const { type, onClick } = props;
  const socailAccount = useWatch({
    name: `${type}_account`,
  }) as Partial<HootsuiteSocialProfile> | undefined;

  if (!socailAccount?.id)
    return (
      <Avatar
        sx={{ cursor: "pointer", border: "2px solid #9d9ea03b" }}
        alt={type}
        src={`/images/logo-${type}.svg`}
        onClick={onClick}
      />
    );

  return (
    <Tooltip title={socailAccount.name}>
      <Badge
        overlap="circular"
        onClick={onClick}
        anchorOrigin={{ vertical: "bottom", horizontal: "right" }}
        sx={{ cursor: "pointer" }}
        badgeContent={
          <Avatar
            sx={{ width: "22px", height: "22px", border: "1px solid white" }}
            alt={type}
            src={`/images/logo-${type}.svg`}
          />
        }>
        <Avatar
          alt={socailAccount.name}
          src={socailAccount.avatar?.url}
          sx={{
            background:
              "linear-gradient(90deg, rgb(151, 150, 240) 0%, rgb(251, 199, 212) 100%)",
            color: "white",
          }}
        />
      </Badge>
    </Tooltip>
  );
};

export default SocialBadge;
