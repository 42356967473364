import { DatabaseObjectResponse } from "@/interfaces/notion-sdk";
import { Workspace } from "@/modules/strapi-sdk/lib/interfaces";
import { strapiInstance } from "@/providers/StrapiProvider";
import { selector, selectorFamily } from "recoil";

export type NotionDbType = keyof Pick<
  Workspace,
  | "notion_account"
  | "notion_notification_cr"
  | "notion_notification_post"
  | "notion_post"
  | "norion_user"
>;

export const notionUnlinkedAccounts = selector({
  key: "notion-unlinked-account",
  get: async () => {
    return await strapiInstance.get("notion/accounts");
  },
});

const notionOptions = selector({
  key: "notion-options-selector",
  get: async () => {
    const res = await strapiInstance.get(
      "notion",
      {},
      {
        populate: "options",
      }
    );
    return res?.data.options || [];
  },
});

export const notionOptionByValue = selectorFamily({
  key: "notion-option-by-value",
  get:
    ({
      collection,
      key,
    }: {
      collection: "clients" | "livrables";
      key: string | undefined;
    }) =>
    ({ get }) => {
      if (!key) return undefined;
      const options = get(notionOptions);

      return options.find(
        ({ value, table }) => table === collection && value === key
      );
    },
});

export const notionTablesQuery = selectorFamily({
  key: "notion-table-query",
  get: (params?: { q?: string; cursor?: string }) => async () => {
    const { results = [], next_cursor } =
      (await strapiInstance.get("notion/databases", {}, params)) || {};

    return { results: results as DatabaseObjectResponse[], next_cursor };
  },
});
