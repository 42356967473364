/** Build with repleek */
// #region Import
import React from "react";

import {
  Avatar,
  AvatarProps,
  Box,
  BoxProps,
  ButtonIcon,
  ButtonIconProps,
  Icon,
  Typography,
  TypographyProps,
} from "@repleek/mui";
import { FaCircleInfo } from "react-icons/fa6";
import { LuUserCircle } from "react-icons/lu";
import { IoPaperPlane } from "react-icons/io5";
import { FiTrash2 } from "react-icons/fi";
// #endregion

// #region Type Definition
type UserCardProps = Partial<BoxProps> & {
  avatar: Partial<AvatarProps>,
  name: TypographyProps & {},
  email: TypographyProps & {},
  type: TypographyProps & {},
  deleteBtn: Partial<ButtonIconProps>,
};
// #endregion

// #region UserCardComponent
const UserCard: React.FC<UserCardProps> = (props) => {
  const { avatar, name, email, type, deleteBtn, ...rootProps } = props;

  return (
    <Box
      tag={"div"}
      css={{
        padding: "8px",
        minWidth: "240px",
        maxWidth: "260px",
        boxShadow:
          "rgba(0, 0, 0, 0.02) 0px 1px 3px 0px , rgba(27, 31, 35, 0.15) 0px 0px 0px 1px",
        borderRadius: "14px",
        gap: "4px",
        position: "relative",
        display: "flex",
        ".mui-dark &": {
          boxShadow:
            "rgba(251, 247, 247, 0.02) 0px 1px 3px 0px  , rgba(241, 243, 246, 0.15) 0px 0px 0px 1px ",
        },
      }}
      {...rootProps}
    >
      <Box tag={"div"} css={{ display: "flex", justifyContent: "center" }}>
        <Avatar
          variant={"circular"}
          component={"div"}
          css={{
            width: "58px",
            height: "58px",
            color: "rgba(255,255,255, 1)",
            background:
              "linear-gradient(90deg, rgb(142, 45, 226) 0%, rgb(74, 0, 224) 100%)",
          }}
          {...avatar}
        />
      </Box>
      <Box tag={"div"} css={{ overflowX: "hidden" }}>
        <Box
          tag={"div"}
          css={{
            padding: "4px",
            gap: "8px",
            display: "flex",
            alignItems: "center",
          }}
        >
          <Icon color={"inherit"} fontSize={"inherit"} icon={LuUserCircle} />
          <Typography
            variant={"overline"}
            text={"Mon titre ici"}
            gutterBottom={false}
            noWrap={false}
            css={{ lineHeight: "16px" }}
            {...name}
          />
        </Box>
        <Box
          tag={"div"}
          css={{
            padding: "4px",
            gap: "8px",
            display: "flex",
            alignItems: "center",
          }}
        >
          <Icon color={"inherit"} fontSize={"inherit"} icon={IoPaperPlane} />
          <Typography
            variant={"overline"}
            text={"Mon titre ici"}
            gutterBottom={false}
            noWrap={false}
            css={{ lineHeight: "16px" }}
            {...email}
          />
        </Box>
        <Box
          tag={"div"}
          css={{
            padding: "4px",
            gap: "8px",
            display: "flex",
            alignItems: "center",
          }}
        >
          <Icon color={"inherit"} fontSize={"inherit"} icon={FaCircleInfo} />
          <Typography
            variant={"overline"}
            text={"Mon titre ici"}
            gutterBottom={false}
            noWrap={false}
            css={{ lineHeight: "16px" }}
            {...type}
          />
        </Box>
      </Box>
      <ButtonIcon
        type={"button"}
        disabled={false}
        disableFocusRipple={false}
        disableRipple={false}
        css={{
          padding: "4px",
          fontSize: "20px",
          boxShadow:
            "rgba(0, 0, 0, 0.02) 0px 1px 3px 0px , rgba(27, 31, 35, 0.15) 0px 0px 0px 1px",
          position: "absolute",
          top: "-4px",
          right: "-4px",
        }}
        Icon={<Icon color={"inherit"} fontSize={"inherit"} icon={FiTrash2} />}
        {...deleteBtn}
      />
    </Box>
  );
};

export default UserCard;
// #endregion
