/** Build with repleek */
// #region Import
import React from "react";

import {
  Box,
  BoxProps,
  Button,
  ButtonProps,
  Image,
  ImageProps,
  Typography,
  TypographyProps,
} from "@repleek/mui";
// #endregion

// #region Type Definition
type WorkspaceNotionItemProps = Partial<BoxProps> & {
  selectBtn: ButtonProps & {},
  icon: Partial<ImageProps>,
  label: TypographyProps & {},
  name: TypographyProps & {},
};
// #endregion

// #region WorkspaceNotionItemComponent
const WorkspaceNotionItem: React.FC<WorkspaceNotionItemProps> = (props) => {
  const { selectBtn, icon, label, name, ...rootProps } = props;

  return (
    <Box
      tag={"div"}
      css={{ fontSize: "12px", marginTop: "16px" }}
      {...rootProps}
    >
      <Box
        tag={"div"}
        css={{
          boxShadow:
            "rgba(0, 0, 0, 0.02) 0px 1px 3px 0px , rgba(27, 31, 35, 0.15) 0px 0px 0px 1px",
          gap: "8px",
          borderRadius: "36px",
          paddingLeft: "12px",
          paddingRight: "8px",
          paddingTop: "8px",
          paddingBottom: "8px",
          display: "flex",
          alignItems: "center",
        }}
      >
        <Image
          alt={"Image description for better SEO"}
          src={"/images/logo-notion.svg"}
          dynamic={true}
          css={{
            padding: "4px",
            height: "36px",
            backgroundColor: "rgba(255,255,255, 1)",
            borderRadius: "36px",
            cursor: "pointer",
          }}
          {...icon}
        />
        <Box tag={"div"} css={{ flexGrow: "1" }}>
          <Typography
            variant={"h3"}
            text={"Bdd Clients"}
            gutterBottom={false}
            noWrap={false}
            css={{ fontSize: "14px", flexGrow: "1" }}
            {...label}
          />
          <Typography
            variant={"body1"}
            text={"Bdd Clients"}
            gutterBottom={false}
            noWrap={false}
            css={{ fontSize: "14px", flexGrow: "1", marginTop: "8px" }}
            {...name}
          />
        </Box>
        <Button
          text={"Selectionner"}
          type={"button"}
          disabled={false}
          disableElevation={false}
          disableFocusRipple={false}
          disableRipple={false}
          fullWidth={false}
          size={"small"}
          variant={"outlined"}
          iconStart={false}
          iconEnd={false}
          css={{
            fontSize: "12px",
            paddingLeft: "14px",
            paddingRight: "14px",
            paddingTop: "8px",
            paddingBottom: "8px",
          }}
          {...selectBtn}
        />
      </Box>
    </Box>
  );
};

export default WorkspaceNotionItem;
// #endregion
