import { useStrapi } from "@/providers/StrapiProvider";
import { authState } from "@/store/auth";
import { useNavigate } from "@repleek/mui";
import { useEffect, useMemo, useState } from "react";
import { useRecoilValue } from "recoil";

type Params = {
  mode: "LOGGED_IN" | "LOGGED_OUT" | "ADMIN" | "CUSTOMER";
  redirect?: string;
};

export const useAuth = ({ mode, redirect }: Params) => {
  const [strapi] = useStrapi();
  const user = useRecoilValue(authState);
  const { navigate } = useNavigate();

  const auth = useMemo(() => {
    switch (mode) {
      case "LOGGED_IN":
        if (!user) {
          redirect && navigate?.(redirect);
          return false;
        }

        return true;

      case "ADMIN":
        if (!user?.type) {
          redirect && navigate?.(redirect);
          return false;
        }
        if (!["cm", "admin"].includes(user.type)) navigate?.("/");
        return true;

      case "LOGGED_OUT":
        if (user) navigate?.(redirect);
        return true;

      case "CUSTOMER":
        if (!user?.type) {
          redirect && navigate?.(redirect);
          return false;
        }
        if (!["customer", "commercial"].includes(user.type)) navigate?.("/");
        return true;
    }
  }, [user, mode, navigate, redirect]);

  useEffect(() => {
    if (["LOGGED_IN", "ADMIN", "CUSTOMER"].includes(mode) && !user) {
      strapi?.fetchUser().then((res) => {
        if (res?.id) strapi.get("/auth/trace");
      });
    }
  }, [mode, strapi, user]);

  return auth;
};

export const useResetToken = (email?: string) => {
  const [strapi] = useStrapi();
  const [token, setToken] = useState<string | undefined>(undefined);

  useEffect(() => {
    if (email) {
      strapi
        ?.get("/auth/:email/reset-token", {
          ":email": email,
        })
        .then((token) => {
          if (token) setToken(token);
        });
    }
  }, [email, strapi]);

  return token;
};
