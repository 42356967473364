import React, { Suspense, useCallback, useMemo, useState } from "react";
import {
  AccountDetail,
  AccountItemSelect,
  NotFound,
  UserAvatar,
} from "@/components";
import Layout, { PistacheLoader } from "@/components/layout";
import { useParams } from "react-router-dom";
import { useRecoilValue } from "recoil";
import { accountSelector } from "@/store/accounts";
import { EmailModal } from "@/components/features/notificationCenter";
import { BusinessCenter } from "@mui/icons-material";
import { useBreadCrumb } from "@/hooks/layout";
import { toast } from "react-toastify";

import Informations from "./informations";
import { Box, Popover } from "@mui/material";
import { useResetToken } from "@/hooks/auth";

const Account: React.FC = () => {
  const { id, page, type } = useParams() as any;

  if (!id) return <NotFound />;

  return (
    <Layout title="Pistache - compte client" mode="ADMIN">
      <Suspense fallback={<PistacheLoader />}>
        <Details id={id} page={page} type={type} />
      </Suspense>
    </Layout>
  );
};

const Details: React.FC<{ id: string; page: string; type: string }> = ({
  id,
  page,
  type,
}) => {
  const [anchorEl, setAnchorEl] = useState<HTMLButtonElement | null>(null);
  const account = useRecoilValue(accountSelector(id));
  const [notification, setNotification] = useState<string | undefined>(
    undefined
  );
  const comunityManagers = useMemo(
    () =>
      account?.comunity_managers?.map(
        ({ firstName, lastName, email, id, avatar }) => ({
          name: [firstName, lastName].filter((v) => v).join(" ") || email,
          initial: [firstName?.[0], lastName?.[0]].filter((v) => v).join(""),
          id,
          src: avatar?.url,
        })
      ),
    [account]
  );

  const resetToken = useResetToken(notification);

  useBreadCrumb([{ label: account?.name, icon: BusinessCenter }]);

  const copyToClipboard = useCallback((text: string) => {
    navigator.clipboard
      .writeText(text)
      .then(() => {
        toast("Text copied to clipboard", { type: "success" });
      })
      .catch((err) => {
        console.error("Failed to copy text: ", err);
        toast("Failed to copy text", { type: "error" });
      });
  }, []);

  const openNotionPage = useCallback(() => {
    if (account?.notion_url) window.open(account.notion_url, "_blank");
  }, [account?.notion_url]);

  const onNotifyUser = useCallback(
    (event: React.MouseEvent<HTMLButtonElement>) => {
      if (!account?.users_access) return setNotification(account?.email);
      if (account?.users_access?.length === 1)
        return setNotification(account.users_access[0].email);
      // select user email
      setAnchorEl(event.currentTarget);
    },
    [account?.email, account?.users_access]
  );

  if (!account?.id) return <NotFound />;

  return (
    <>
      <AccountDetail
        editBtn={{ href: `/account/${id}` }}
        logo={{ src: account?.url || "/images/logo-pistache.jpg" }}
        name={{ text: account.name }}
        email={{ text: account.email }}
        notification={{ onClick: onNotifyUser }}
        informationTab={{
          variant: !page || page === "informations" ? "contained" : "text",
          href: `/account/${id}`,
        }}
        reportingTab={{
          variant: page === "reporting" ? "contained" : "text",
          href: `/account/${id}/reporting`,
        }}
        planningTab={{
          variant: page === "planning" ? "contained" : "text",
          href: `/account/${id}/planning`,
        }}
        leadsTab={{
          variant: page === "leads" ? "contained" : "text",
          href: `/account/${id}/leads`,
        }}
        gmbTab={{
          variant: page === "gmb" ? "contained" : "text",
          href: `/account/${id}/gmb`,
          hidden: !account.workspace?.gmb_account_manager,
        }}
        hootsuitId={{
          text: `RAPPORT PIST'APP ${account.name.toUpperCase()} ID:${
            account.id
          }`,
        }}
        copy={{
          onClick: () =>
            copyToClipboard(
              `RAPPORT PIST'APP ${account.name.toUpperCase()} ID:${account.id}`
            ),
        }}
        notionUrl={{
          hidden: !account.notion_url,
          onClick: openNotionPage,
        }}
        comunityManagers={{
          children: (
            <>
              {comunityManagers?.map(({ id, src, initial, name }) => (
                <UserAvatar
                  key={id}
                  avatar={{ initial, src }}
                  name={{ text: name }}
                />
              ))}
            </>
          ),
        }}
      />

      <Informations id={id} page={page} type={type} />

      <EmailModal
        loading={!!notification && !resetToken}
        open={!!notification}
        content={{
          resetPassword: {
            cta: {
              link: `https://dashboard.agence-pistache.fr/reset-password?token=${resetToken}`,
            },
          },
          welcomeEmail: {
            cta: {
              link: `https://dashboard.agence-pistache.fr/reset-password?token=${resetToken}&name=${account?.name}`,
            },
          },
        }}
        email={notification}
        onClose={() => setNotification(undefined)}
      />
      <Popover
        id={id}
        open={Boolean(anchorEl)}
        anchorEl={anchorEl}
        onClose={() => setAnchorEl(null)}
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "center",
        }}
        transformOrigin={{
          vertical: "top",
          horizontal: "center",
        }}
        slotProps={{
          paper: {
            sx: {
              borderRadius: "10px",
              overflow: "hidden",
              mt: 1,
              maxHeight: "480px",
              overflowY: "auto",
            },
          },
        }}
      >
        <Box sx={{ mb: 2, width: 240 }}>
          {account.users_access?.map(({ id, email, avatar }) => (
            <AccountItemSelect
              key={id}
              avatar={{
                src: avatar?.url,
                initial: email?.[0],
              }}
              name={{ text: email }}
              onClick={() => {
                setNotification(email);
                setAnchorEl(null);
              }}
            />
          ))}
        </Box>
      </Popover>
    </>
  );
};

export default Account;
