import { selector, selectorFamily } from "recoil";
import { authState } from "./auth";
import { strapiInstance } from "@/providers/StrapiProvider";
import {
  HootsuiteSocialProfile,
  User,
} from "@/modules/strapi-sdk/lib/interfaces";

export const workspaceUsers = selector({
  key: "workspace-users",
  get: async ({ get }) => {
    const me = get(authState);
    if (me?.workspaces?.length) {
      return (
        await strapiInstance.find("workspaces", {
          fields: ["id"],
          filters: { id: { $in: me.workspaces.map(({ id }) => id) } },
          populate: { users: true },
        })
      )?.data?.flatMap(({ users }) => users) as User[];
    }
    return [];
  },
});

export const cmUsersByWorkspace = selector({
  key: "cm-users",
  get: ({ get }) => {
    const users = get(workspaceUsers);
    if (users?.length)
      return users
        .filter(({ type }) => type === "cm")
        .map(({ email, firstName }) => ({
          label: `${firstName} - ${email}`,
          value: email,
        }));

    return [
      {
        label: "Carla - carla@agence-pistache.fr",
        value: "carla@agence-pistache.fr",
      },
      {
        label: "Ornella - ornella@agence-pistache.fr",
        value: "ornella@agence-pistache.fr",
      },
      {
        label: "Eva - eva@agence-pistache.fr",
        value: "eva@agence-pistache.fr",
      },
      {
        label: "Karla - karla@agence-pistache.fr",
        value: "karla@agence-pistache.fr",
      },
      {
        label: "Alice - alice@agence-pistache.fr",
        value: "alice@agence-pistache.fr",
      },
      {
        label: "Ambre - ambre@m-com.fr",
        value: "ambre@m-com.fr",
      },
    ];
  },
});

export const myWorkspaces = selector({
  key: "my-workspaces",
  get: async ({ get }) => {
    const me = get(authState);
    if (me?.type === "customer") return [];

    if (me?.workspaces?.length)
      return (
        await strapiInstance.find("workspaces", {
          fields: ["id", "name"],
          filters: { id: { $in: me.workspaces.map(({ id }) => id) } },
        })
      )?.data;
    else
      return (
        await strapiInstance.find("workspaces", { fields: ["id", "name"] })
      )?.data;
  },
});

export const workspaceById = selectorFamily({
  key: "workspace-by-id",
  get: (id?: number) => async () => {
    if (!id) return undefined;
    return await strapiInstance.findOne("workspaces", id, {
      populate: {
        avatar: true,
        gmb_account_manager: true,
        hootsuite_account_manager: true,
        norion_user: true,
        notion_account: true,
        notion_notification_cr: true,
        notion_notification_post: true,
        notion_post: true,
      },
    });
  },
});

export const gmbUnlinkedAccounts = selectorFamily({
  key: "gmb-unlinked-account",
  get: (id: number) => async () => {
    return await strapiInstance.get("workspaces/:id/gmb-accounts", {
      ":id": id,
    });
  },
});

export const hootsuiteSocialProfiles = selectorFamily({
  key: "hootsuite-social-profile",
  get: (id: number) => async () => {
    const res = await strapiInstance.get("hootsuite/:wkId/social-profiles", {
      ":wkId": id,
    });

    return res?.map(
      ({
        type,
        socialNetworkUsername: name,
        isReauthRequired,
        socialNetworkId,
        id,
        avatarUrl,
      }) => {
        const profile: HootsuiteSocialProfile & { avatarUrl: string } = {
          name,
          isReauthRequired: !!isReauthRequired,
          hootsuiteId: id.toString(),
          socialNetworkId,
          avatarUrl,
        };
        switch (type) {
          case "LINKEDINCOMPANY":
            profile.type = "linkedin";
            break;
          case "INSTAGRAMBUSINESS":
            profile.type = "instagram";
            break;
          case "FACEBOOKPAGE":
            profile.type = "facebook";
            break;
          case "TIKTOKBUSINESS":
            profile.type = "tiktok";
            break;
        }
        return profile;
      }
    );
  },
});

export const hootsuiteSocialProfilesByType = selectorFamily({
  key: "hootsuite-social-profile-type",
  get:
    (params: { id: number; type: HootsuiteSocialProfile["type"] }) =>
    ({ get }) => {
      const { id } = params;
      const profiles = get(hootsuiteSocialProfiles(id));

      return profiles?.filter(({ type }) => type === params.type) || [];
    },
});
