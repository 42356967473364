/** Build with repleek */
// #region Import
import React from "react";

import {
  Avatar,
  AvatarProps,
  Box,
  BoxProps,
  Icon,
  IconProps,
  Typography,
  TypographyProps,
} from "@repleek/mui";
import { FaCircleCheck } from "react-icons/fa6";
// #endregion

// #region Type Definition
type NotionItemProps = Partial<BoxProps> & {
  avatar: Partial<AvatarProps>,
  name: TypographyProps & {},
  selected: Partial<IconProps>,
  email: TypographyProps & {},
};
// #endregion

// #region NotionItemComponent
const NotionItem: React.FC<NotionItemProps> = (props) => {
  const { avatar, name, selected, email, ...rootProps } = props;

  return (
    <Box
      tag={"div"}
      css={{
        padding: "8px",
        maxWidth: "400px",
        boxShadow:
          "rgba(0, 0, 0, 0.02) 0px 1px 3px 0px , rgba(27, 31, 35, 0.15) 0px 0px 0px 1px",
        gap: "8px",
        borderRadius: "16px",
        marginBottom: "12px",
        cursor: "pointer",
        display: "flex",
        alignItems: "center",
        ".mui-dark &": {
          boxShadow:
            "rgba(254, 253, 253, 0.02) 0px 1px 3px 0px  , rgba(255, 255, 255, 0.15) 0px 0px 0px 1px ",
        },
        ":hover": {
          boxShadow:
            "rgba(50, 50, 93, 0.25) 0px 2px 5px -1px , rgba(0, 0, 0, 0.3) 0px 1px 3px -1px",
        },
      }}
      {...rootProps}
    >
      <Avatar
        variant={"circular"}
        component={"div"}
        css={{ width: "54px", height: "54px" }}
        {...avatar}
      />
      <Box tag={"div"} css={{ overflow: "hidden", flexGrow: "1" }}>
        <Typography
          variant={"h3"}
          text={"Mon titre ici"}
          gutterBottom={false}
          noWrap={false}
          css={{ fontSize: "16px" }}
          {...name}
        />
        <Typography
          variant={"body1"}
          text={"Mon titre ici"}
          gutterBottom={false}
          noWrap={false}
          {...email}
        />
      </Box>
      <Icon
        color={"primary"}
        fontSize={"inherit"}
        icon={FaCircleCheck}
        {...selected}
      />
    </Box>
  );
};

export default NotionItem;
// #endregion
