import React, {
  ChangeEvent,
  Suspense,
  useCallback,
  useMemo,
  useRef,
  useState,
} from "react";
import CollectionsGrid from "./CollectionsGrid";
import CollectionCard from "./CollectionCard";
import {
  useRecoilRefresher_UNSTABLE,
  useRecoilState,
  useRecoilValue,
} from "recoil";
import {
  collectionListSelector,
  collectionQuery,
  getCollectionQueryCount,
} from "@/store/collection-list";
import { ContentType } from "@/modules/strapi-sdk/lib/api";
import { Pagination, Skeleton } from "@mui/material";
import { Account, User, Workspace } from "@/modules/strapi-sdk/lib/interfaces";
import { Modal } from "@/components/layout";
import { useDebounce } from "@/hooks/utility";
import { useStrapi } from "@/providers/StrapiProvider";
import CollectionsFilter, { CollectionFilter } from "./CollectionsFilter";
import { Primitive } from "@/interfaces";
import { ButtonIconProps, ButtonProps, WithCss } from "@repleek/mui";

type Props = {
  collection: keyof ContentType;
  id: string;
  filters: CollectionFilter[];
  defaultFilters?: Record<string, Primitive | Primitive[]>;
  onCreate?: () => void;
  onOpen?: (id: number) => void;
  onUpdate?: (id: number) => void;
  deleteItem?: boolean;
  actions?: {
    editBtn?: ButtonIconProps;
    deleteBtn?: ButtonIconProps;
    openBtn?: ButtonProps;
  };
};

const CollectionList: React.FC<Props> = (props) => {
  const { collection, id, onCreate, filters, defaultFilters, ...rest } = props;
  const searchInput = useRef<HTMLInputElement>(null);

  const [query, setQuery] = useRecoilState(collectionQuery({ collection, id }));
  const counts = useRecoilValue(getCollectionQueryCount({ collection, id }));
  const filterBadge = useMemo<WithCss<{}>["css"]>(
    () =>
      counts.filters
        ? {
            ".filter-btn": {
              position: "relative",
              "&::after": {
                content: `"${counts.filters}"`,
                position: "absolute",
                top: "-8px",
                right: "-8px",
                borderRadius: "16px",
                background: "tomato",
                width: "22px",
                height: "22px",
                fontSize: "12px",
                color: "white",
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
              },
            },
          }
        : undefined,
    [counts.filters]
  );

  const [, { loading }] = useStrapi();
  const [filter, setFilter] = useState(!!counts.filters);

  const onSearch = useCallback(
    (event: ChangeEvent<HTMLInputElement> | string) => {
      const search = typeof event === "string" ? event : event.target.value;
      setQuery((q) => ({
        ...q,
        search,
        pagination: { ...q.pagination, page: 1 },
      }));
    },
    [setQuery]
  );

  const onFilters = useCallback(
    (value: Record<string, any>) => {
      setQuery((q) => ({
        ...q,
        ...value,
        pagination: { ...q.pagination, page: 1 },
      }));
    },
    [setQuery]
  );

  const onClearSearch = useCallback(() => {
    if (searchInput.current && !loading) {
      searchInput.current.value = "";
      onSearch("");
    }
  }, [loading, onSearch]);

  const onRefresh = useCallback(() => {
    setQuery((q) => ({
      ...q,
    }));
  }, [setQuery]);

  const debouncedSearch = useDebounce(onSearch, 500);
  const debouncedFilters = useDebounce(onFilters, 500);

  return (
    <CollectionsGrid
      css={filterBadge}
      searchField={{
        ref: searchInput,
        defaultValue: query.search || "",
        onChange: debouncedSearch as any,
        readOnly: loading,
      }}
      filterBtn={{
        onClick: () => setFilter((f) => !f),
        hidden: !filters.length,
      }}
      refreshBtn={{ onClick: onRefresh, disabled: loading }}
      clearSearch={{
        hidden: !query.search,
        onClick: onClearSearch,
      }}
      newBtn={{ hidden: !onCreate, onClick: onCreate }}
      filterBox={{
        children: (
          <CollectionsFilter
            open={filter}
            filters={filters}
            value={query}
            onChange={debouncedFilters}
          />
        ),
      }}
      items={{
        children: (
          <Suspense
            fallback={[1, 2, 3, 4, 5, 6, 7, 8, 9].map((key) => (
              <Skeleton key={key} height={138} sx={{ transform: "unset" }} />
            ))}
          >
            <Items
              id={id}
              collection={collection}
              defaultFilters={defaultFilters}
              {...rest}
            />
          </Suspense>
        ),
        css: {
          display: "grid",
          gridTemplateColumns: "repeat(3, 1fr)",
          gap: "16px",
        },
      }}
      pagination={{
        children: (
          <Suspense
            fallback={
              <Skeleton
                height={38}
                width={360}
                sx={{ transform: "unset", borderRadius: "16px" }}
              />
            }
          >
            <CollectionPagination
              id={id}
              collection={collection}
              defaultFilters={defaultFilters}
            />
          </Suspense>
        ),
      }}
    />
  );
};

const Items: React.FC<
  Pick<
    Props,
    | "collection"
    | "onOpen"
    | "deleteItem"
    | "onUpdate"
    | "defaultFilters"
    | "actions"
  > & { id: string }
> = (props) => {
  const {
    collection,
    id,
    onOpen,
    onUpdate,
    defaultFilters,
    deleteItem,
    actions,
  } = props;
  const [strapi] = useStrapi();
  const [elToDelete, setElToDelete] = useState<
    { id: number; name: string } | undefined
  >(undefined);

  const res = useRecoilValue(
    collectionListSelector({ collection, id, defaultFilters })
  );
  const [query, setQuery] = useRecoilState(collectionQuery({ collection, id }));
  const refreshItems = useRecoilRefresher_UNSTABLE(
    collectionListSelector({ collection, id })
  );

  const items = useMemo(
    () =>
      res?.data?.map(({ id, ...item }) => {
        switch (collection) {
          case "accounts":
          case "me/accounts": {
            const { email, name, url } = item as Account;
            return {
              id,
              name,
              description: email,
              avatar: url,
              initial: name[0],
            };
          }

          case "workspaces": {
            const { avatar, name } = item as Workspace;
            return {
              id,
              name,
              description: "",
              avatar: avatar?.url,
              initial: name?.[0],
            };
          }

          case "me/users": {
            const { avatar, email, lastName, firstName } = item as User;
            return {
              id,
              name: [lastName, firstName].join(" "),
              description: email,
              avatar: avatar?.url,
              initial: lastName?.[0],
            };
          }
          case "social-statistics":
          case "social-reports":
          case "messages":
          case "posts":
          case "notifications":
          case "sheets":
          case "leads":
          default:
            return {
              id,
              name: "",
              description: "",
              avatar: "",
              initial: "",
            };
        }
      }) || [],
    [collection, res?.data]
  );

  const labels = useMemo(() => {
    switch (collection) {
      case "me/accounts":
      case "accounts":
        return {
          title: "Suppression de compte",
          message: `Êtes-vous sûr de vouloir supprimer le compte de <b><u>${elToDelete?.name}</u></b> ? Cette action est irréversible et toutes les données associées seront définitivement perdues.`,
        };

      case "workspaces":
        return {
          title: "Suppression de workspace",
          message: `Êtes-vous sûr de vouloir supprimer le workspace <b><u>${elToDelete?.name}</u></b> ? Cette action est irréversible et toutes les données associées seront définitivement perdues.`,
        };
      case "me/users":
        return {
          title: "Suppression d'utilisateur",
          message: `Êtes-vous sûr de vouloir supprimer l'utilisateur <b><u>${elToDelete?.name}</u></b> ? Cette action est irréversible et toutes les données associées seront définitivement perdues.`,
        };

      default:
        return {
          title: "",
          message: "",
        };
    }
  }, [collection, elToDelete?.name]);

  const onDelete = useCallback(async () => {
    if (elToDelete && deleteItem) {
      const res = await strapi?.delete(collection, elToDelete.id);
      if (res?.data) {
        setElToDelete(undefined);
        if (query.pagination.page > 1)
          setQuery((q) => ({ ...q, pagination: { ...q.pagination, page: 1 } }));
        else refreshItems();
      }
    }
  }, [
    collection,
    deleteItem,
    elToDelete,
    query.pagination.page,
    refreshItems,
    setQuery,
    strapi,
  ]);

  return (
    <>
      {items.map(({ id, name, description, avatar, initial }) => (
        <CollectionCard
          key={id}
          name={{ text: name }}
          deleteBtn={{
            hidden: !deleteItem,
            onClick: () => id && name && setElToDelete({ id, name }),
            ...actions?.deleteBtn,
          }}
          editBtn={{
            onClick: () => id && onUpdate?.(id),
            ...actions?.editBtn,
          }}
          openBtn={{ onClick: () => id && onOpen?.(id), ...actions?.openBtn }}
          avatar={{ src: avatar, initial }}
          description={{ hidden: !description, text: description }}
          style={{
            wordBreak: "break-word",
          }}
        />
      ))}

      <Modal
        type="box"
        open={!!elToDelete}
        onClose={() => setElToDelete(undefined)}
        onConfirm={onDelete}
        title={labels.title}
        message={labels.message}
      />
    </>
  );
};

const CollectionPagination: React.FC<
  Pick<Props, "collection" | "id" | "defaultFilters">
> = (props) => {
  const { collection, id, defaultFilters } = props;

  const [query, setQuery] = useRecoilState(collectionQuery({ collection, id }));
  const res = useRecoilValue(
    collectionListSelector({ collection, id, defaultFilters })
  );

  const handlePaginate = useCallback(
    (_event: any, page: number) => {
      setQuery((q) => ({ ...q, pagination: { ...q.pagination, page } }));
    },
    [setQuery]
  );

  const count = useMemo(
    () => res?.meta.pagination.pageCount || 0,
    [res?.meta.pagination.pageCount]
  );

  return (
    <Pagination
      color="secondary"
      hidden={count <= 1}
      count={count}
      page={query.pagination.page}
      onChange={handlePaginate}
    />
  );
};

export default CollectionList;
