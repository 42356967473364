import React, { Suspense, useCallback, useMemo, useState } from "react";
import { AccountForm, NotFound } from "@/components";
import Layout, { PistacheLoader } from "@/components/layout";
import {
  Account,
  HootsuiteSocialProfile,
} from "@/modules/strapi-sdk/lib/interfaces";
import { useStrapi } from "@/providers/StrapiProvider";
import { accountSelector, accountsSelector } from "@/store/accounts";
import { useNavigate } from "@repleek/mui";
import { useParams } from "react-router-dom";
import { useRecoilRefresher_UNSTABLE, useRecoilValue } from "recoil";

import BasicInfo from "./BasicInfo";
import SocialBadge from "./hootsuite-select/SocialBadge";
import NotionSelect from "./notion-select";
import HootsuiteSelect from "./hootsuite-select";
import { myWorkspaces } from "@/store/workspace";

const NewAccount: React.FC = () => {
  return (
    <Layout title="Pistache - nouveau compte" mode="ADMIN">
      <Suspense fallback={<PistacheLoader />}>
        <NewAccountForm />
      </Suspense>
    </Layout>
  );
};

export const NewAccountForm: React.FC = () => {
  const { id } = useParams();
  const [strapi, { loading }] = useStrapi();
  const { navigate } = useNavigate();
  const account = useRecoilValue(accountSelector(id));
  const refreshAccount = useRecoilRefresher_UNSTABLE(accountSelector(id));
  const refreshAccounts = useRecoilRefresher_UNSTABLE(accountsSelector);

  const [openNotion, setOpenNotion] = useState(false);
  const [openHootsuite, setOpenHootsuite] = useState<
    HootsuiteSocialProfile["type"] | undefined
  >(undefined);

  const defaultValues = useMemo(() => {
    if (account) {
      return { ...account, workspace: account.workspace?.id };
    }

    return {
      name: "",
      email: "",
      type: "classic",
      configuration: { facebook: true, instagram: true, tiktok: true },
      notion_id: "",
    };
  }, [account]);

  const css = useMemo(
    () =>
      id
        ? {
            ".MuiPaper-root": {
              padding: "16px 46px",
              border: "none",
            },
            ".footer": {
              justifyContent: "end",
            },
          }
        : undefined,
    [id]
  );

  const workspacesOptions = useMemo(() => {
    let wks: any[] = [];

    if (account?.workspace) wks = [...wks, account.workspace];

    return Array.from(
      new Map(
        wks.map(({ id, name }) => [id, { value: id, label: name }])
      ).values()
    ) as { value: number; label: string }[];
  }, [account?.workspace]);

  const onSubmit = useCallback(
    async (value: Account) => {
      let newAccount: Account | undefined;
      if (!id) {
        // create
        const res = await strapi?.create("me/accounts", value);
        newAccount = res?.data;
      } else {
        // update
        const res = await strapi?.update("me/accounts", id, value);
        newAccount = res?.data;
      }
      if (newAccount) {
        navigate?.(`/account/${newAccount.id}`);
        refreshAccount();
        refreshAccounts();
      }
    },
    [id, navigate, refreshAccount, refreshAccounts, strapi]
  );

  if (!account && id) return <NotFound />;

  return (
    <AccountForm
      title={{ text: id ? "Mise à jour" : "Nouveau compte" }}
      form={{ onSubmit, defaultValues }}
      email={{ inputName: "email", disabled: !!id }}
      back={{
        onClick: () => navigate?.(id ? `/account/${id}` : "/accounts"),
        hidden: !!id,
      }}
      type={{
        inputName: "type",
        options: [
          { label: "Classique", value: "classic" },
          { label: "Réstaurant", value: "restaurant" },
        ],
      }}
      fields={{ hidden: !openHootsuite && !openNotion && loading }}
      loader={{
        children: <PistacheLoader css={{ height: "100%" }} />,
        hidden: openNotion || !!openHootsuite || !loading,
      }}
      notionSync={{
        onClick: () => setOpenNotion(true),
        iconStart: !!account?.notion_id,
      }}
      hottsuiteSync={{
        children: <SocialBadge onOpenHootsuite={setOpenHootsuite} />,
      }}
      name={{ disabled: !!account?.notion_id }}
      logo={{
        hidden: !id,
        ...(account?.url && {
          src: account.url,
          sx: {
            background: "transparent !important",
          },
        }),
        initial: account?.name?.substring(0, 2),
      }}
      modal={{
        children: (
          <>
            {account && (
              <HootsuiteSelect
                accountType={openHootsuite}
                onClose={() => setOpenHootsuite(undefined)}
                account={account}
                refreshAccount={refreshAccount}
              />
            )}
            <NotionSelect
              open={openNotion}
              onClose={() => setOpenNotion(false)}
            />
          </>
        ),
      }}
      basicInfo={{ children: <BasicInfo /> }}
      css={css}
      workspace={{
        options: workspacesOptions,
        disabled: true,
      }}
    />
  );
};

export default NewAccount;
