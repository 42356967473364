import { strapiInstance } from "@/providers/StrapiProvider";
import { atom, selector, selectorFamily } from "recoil";
import { authState } from "./auth";
import { PaginationByPage } from "@/modules/strapi-sdk";

export const accountQuery = atom<{
  pagination: PaginationByPage;
  search?: string;
  type?: ("classic" | "restaurant")[];
}>({
  key: "account-query",
  default: {
    pagination: {
      page: 1,
      pageSize: 5,
      withCount: true,
    },
  },
});

export const accountsSelector = selector({
  key: "accounts-selector",
  get: async ({ get }) => {
    const user = get(authState);
    const { pagination, search, type } = get(accountQuery);

    try {
      if (user)
        return await strapiInstance.find("me/accounts", {
          pagination,
          filters: {
            ...(search && {
              $or: [
                {
                  name: { $containsi: search },
                },
                {
                  email: { $containsi: search },
                },
              ],
            }),
            type: {
              $in: type,
            },
          },
          fields: ["id", "name", "email", "type", "url"],
          sort: ["createdAt:DESC"],
        });
    } catch (error) {
      console.log("error: ", error);
      return undefined;
    }
  },
});

export const accountSelector = selectorFamily({
  key: "account-selector",
  get:
    (param: string | number | undefined) =>
    async ({ get }) => {
      if (param) {
        const res = await strapiInstance.findOne("me/accounts", param, {
          fields: ["*"],
          populate: {
            configuration: true,
            comunity_managers: {
              populate: ["avatar"],
            },
            avatar: true,
            sheet: true,
            gmb_page: true,
            workspace: {
              populate: {
                gmb_account_manager: true,
              },
            },
            users_access: {
              populate: {
                avatar: true,
              },
            },
            facebook_account: {
              populate: {
                avatar: true,
              },
            },
            instagram_account: {
              populate: {
                avatar: true,
              },
            },
            tiktok_account: {
              populate: {
                avatar: true,
              },
            },
            linkedin_account: {
              populate: {
                avatar: true,
              },
            },
          },
        });

        if (res?.id) {
          const resetToken = await strapiInstance.get(
            "/auth/:email/reset-token",
            { ":email": res.email }
          );

          return { ...res, notion_id: res.notion_id || "", resetToken };
        }
        return undefined;
      }
    },
});
