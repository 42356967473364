import { Button } from "@mui/material";
import React, { Suspense, useMemo } from "react";
import { NavLink } from "react-router-dom";
import { navigations } from "@/pages";
import { LuUsers } from "react-icons/lu";
import { useRecoilValue } from "recoil";
import { accountSelected } from "@/store/auth";
import { User } from "@/modules/strapi-sdk/lib/interfaces";

type Props = {
  type: User["type"];
};

const Menu: React.FC<Props> = ({ type }) => {
  const myAccount = useRecoilValue(accountSelected);

  const menu = useMemo(() => {
    switch (type) {
      case "admin":
      case "cm":
        let adminMenu = navigations["admin"];

        return adminMenu.filter(
          (item, index, self) =>
            index === self.findIndex((obj) => obj.href === item.href)
        );

      case "customer":
      case "commercial":
        let userMenu = navigations["customer"];
        if (myAccount?.sheet?.id) {
          userMenu = [
            ...userMenu,
            {
              label: "Leads",
              href: "/leads",
              icon: LuUsers,
            },
          ];
        }
        return userMenu;

      default:
        return navigations["default"];
    }
  }, [myAccount?.sheet?.id, type]);

  if (!menu.length) return null;

  return (
    <>
      {menu.map(({ href, label, icon: Icon }) => (
        <NavLink key={href} to={href} end>
          {({ isActive }) => (
            <Button
              startIcon={<Icon style={{ marginRight: "8px" }} />}
              sx={{
                justifyContent: "start",
                borderColor: "transparent",
                padding: "8px 16px",
                borderRadius: "10px",
                boxShadow: "none",
                ...(isActive && {
                  border: "1px solid",
                }),
                mb: 0.5,
                ":hover": {
                  boxShadow: "none",
                },
              }}
              fullWidth
              color="secondary"
              size="small"
              variant="outlined"
            >
              {label}
            </Button>
          )}
        </NavLink>
      ))}
    </>
  );
};

const SuspendedMenu: React.FC<Props> = (props) => {
  return (
    <Suspense>
      <Menu {...props} />
    </Suspense>
  );
};

export default SuspendedMenu;
