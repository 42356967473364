import {
  Box,
  ButtonBase,
  Checkbox,
  FormControlLabel,
  Popover,
} from "@mui/material";
import React, { useCallback, useMemo, useState } from "react";
import { IoMdClose } from "react-icons/io";

type Value = string | number | boolean;

type Props = {
  label: string;
  value: Value[];
  options: { label: string; value: string | number | boolean }[];
  multiple: boolean;
  onChange: (value: Value[]) => void;
};

const TagField: React.FC<Props> = (props) => {
  const { value, options, label, multiple, onChange } = props;

  const valueLabel = useMemo(
    () =>
      options
        .filter((option) => value.includes(option.value))
        .map(({ label }) => label)
        .join(", "),
    [options, value]
  );

  const [anchorEl, setAnchorEl] = useState<HTMLButtonElement | null>(null);

  const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };
  const open = Boolean(anchorEl);

  const handleChange = useCallback(
    (newValue: Value) => {
      if (multiple)
        onChange(
          value.includes(newValue)
            ? value.filter((v) => v !== newValue)
            : [...value, newValue]
        );
      else onChange([newValue]);

      handleClose();
    },
    [multiple, onChange, value]
  );

  return (
    <>
      <ButtonBase
        sx={{
          padding: "8px 16px",
          borderRadius: "16px",
          border: "1px solid rgba(187, 188, 188, 0.41)",
          b: {
            marginLeft: "0.5rem",
          },
          ".close": {
            fontSize: "1.3rem",
            ml: 1.5,
          },
        }}
        onClick={handleClick}
      >
        <span>{label}</span>
        {valueLabel && (
          <>
            <b>{valueLabel}</b>
            <IoMdClose
              className="close"
              onClick={(e) => {
                e.preventDefault();
                e.stopPropagation();
                onChange([]);
              }}
            />
          </>
        )}
      </ButtonBase>
      <Popover
        open={open}
        anchorEl={anchorEl}
        onClose={handleClose}
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "left",
        }}
        slotProps={{
          paper: {
            sx: {
              borderRadius: "10px",
              overflow: "hidden",
              mt: "4px",
            },
          },
        }}
      >
        <Box sx={{ width: 280 }}>
          {options.map(({ label, value }) => (
            <FormControlLabel
              key={label}
              control={<Checkbox checked={props.value.includes(value)} />}
              label={label}
              sx={{
                pl: 1,
                m: 0,
                width: "100%",
                ":hover": {
                  backgroundColor: "#e5edf35c",
                },
              }}
              onClick={() => handleChange(value)}
            />
          ))}
        </Box>
      </Popover>
    </>
  );
};

export default TagField;
