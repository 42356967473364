import React from "react";
import ReportingComponent from "../reports/ReportingComponent";
import { useRecoilValue } from "recoil";
import { accountSelected } from "@/store/auth";
import { useStrapi } from "@/providers/StrapiProvider";
import { PistacheLoader } from "@/components/layout";

const UserHome: React.FC = () => {
  const account = useRecoilValue(accountSelected);
  const [, { loading }] = useStrapi();

  if (loading && !account?.id) return <PistacheLoader />;
  if (!account?.id) return null;

  return <ReportingComponent accountId={account.id.toString()} />;
};

export default UserHome;
