/** Build with repleek */
// #region Import
import React from "react";

import {
  Avatar,
  AvatarProps,
  Box,
  BoxProps,
  Typography,
  TypographyProps,
} from "@repleek/mui";
// #endregion

// #region Type Definition
type AccountItemSelectProps = Partial<BoxProps> & {
  avatar: Partial<AvatarProps>,
  name: TypographyProps & {},
};
// #endregion

// #region AccountItemSelectComponent
const AccountItemSelect: React.FC<AccountItemSelectProps> = (props) => {
  const { avatar, name, ...rootProps } = props;

  return (
    <Box
      tag={"div"}
      css={{
        padding: "8px",
        gap: "8px",
        display: "flex",
        alignItems: "center",
        ":hover": { backgroundColor: "rgba(7, 7, 7, 0.12)", cursor: "pointer" },
      }}
      {...rootProps}
    >
      <Avatar variant={"circular"} component={"div"} {...avatar} />
      <Typography
        variant={"h4"}
        text={"Mon titre ici"}
        gutterBottom={false}
        noWrap={false}
        css={{ fontSize: "12px" }}
        {...name}
      />
    </Box>
  );
};

export default AccountItemSelect;
// #endregion
