import React, { Suspense } from "react";
import Layout, { PistacheLoader } from "@/components/layout";
import CollectionList from "@/components/layout/collections";
import { useNavigate } from "@repleek/mui";
import WorkspaceForm from "@/components/features/workspace/WorkspaceForm";

const Workspaces: React.FC = () => {
  const { navigate } = useNavigate();

  return (
    <Layout title="Pistache - workspaces" mode="ADMIN">
      <Suspense fallback={<PistacheLoader />}>
        <CollectionList
          collection={"workspaces"}
          id="workspaces"
          filters={[]}
          deleteItem
          onCreate={() => navigate?.("/workspaces?action=new")}
          onUpdate={(id) =>
            navigate?.(`/workspaces/${id}/informations?action=edit`)
          }
          onOpen={(id) => navigate?.(`/workspaces/${id}/informations`)}
        />
        <WorkspaceForm />
      </Suspense>
    </Layout>
  );
};

export default Workspaces;
