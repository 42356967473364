import React, { useMemo, useState, useCallback } from "react";
import SocialStatus from "./SocialStatus";
import { IconProps } from "@repleek/mui";
import { Button } from "@mui/material";
import { useRecoilValue } from "recoil";
import { useParams } from "react-router-dom";
import { accountSelector } from "@/store/accounts";
import dayjs from "dayjs";
import { Modal } from "@/components/layout";
import SocialStatisticTable from "./SocialStatisticTable";
import { SiFacebook, SiLinkedin, SiTiktok } from "react-icons/si";
import { SlSocialInstagram } from "react-icons/sl";

type Social = "linkedin" | "tiktok" | "facebook" | "instagram";

type Props = {
  type: Social;
  header?: boolean;
  lastUpdated?: Date | string;
  status?: "LINKED" | "SESSION_EXPIRED";
  status_message?: string;
};

export const socialIcons: Record<Social, { icon: IconProps; title: string }> = {
  tiktok: {
    icon: {
      icon: SiTiktok,
      style: { color: "#000000" },
    },
    title: "Tiktok",
  },
  facebook: {
    icon: {
      icon: SiFacebook,
      style: { color: "#1877F2" },
    },
    title: "Facebook",
  },
  instagram: {
    icon: {
      icon: SlSocialInstagram,
      style: { color: "#833AB4" },
    },
    title: "Instagram",
  },
  linkedin: {
    icon: {
      icon: SiLinkedin,
      style: { color: "#0A66C2" },
    },
    title: "LinkedIn",
  },
};

const SocialDetail: React.FC<Props> = (props) => {
  const { type, lastUpdated } = props;
  const { id } = useParams();
  const account = useRecoilValue(accountSelector(id));
  //const refreshAccount = useRecoilRefresher_UNSTABLE(accountSelector(id));
  //const [strapi] = useStrapi();
  const [loading] = useState(false);
  const [showStatModal, setSetshowStatModal] = useState(false);

  const socialAccount = useMemo(
    () => account?.[`${type}_account`],
    [account, type]
  );
  const { icon } = useMemo(() => socialIcons[type], [type]);

  const onShowStatModal = useCallback(() => setSetshowStatModal(true), []);

  const actions = useMemo(() => {
    return (
      <Button
        variant="text"
        color="secondary"
        sx={{ padding: "6px 18px", boxShadow: "none", border: "none" }}
        onClick={onShowStatModal}
        disabled={loading}>
        Statistiques
      </Button>
    );
  }, [onShowStatModal, loading]);

  const _lastUpdated = useMemo(
    () => (lastUpdated ? dayjs(lastUpdated).fromNow() : ""),
    [lastUpdated]
  );

  return (
    <>
      <SocialStatus
        action={{ children: actions }}
        icon={icon}
        lastUpdated={{ text: _lastUpdated }}
      />

      <Modal
        type="box"
        open={showStatModal}
        title={`Daily ${type} insight`}
        icon={icon}
        onClose={() => setSetshowStatModal(false)}
        noActions
        width="md">
        <SocialStatisticTable id={socialAccount?.id} />
      </Modal>
    </>
  );
};

export default SocialDetail;
