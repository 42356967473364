/** Build with repleek */
// #region Import
import React from "react";

import {
  Box,
  BoxProps,
  ButtonIcon,
  ButtonIconProps,
  Icon,
  IconProps,
  InputBase,
  InputBaseProps,
} from "@repleek/mui";
import { CiSearch } from "react-icons/ci";
import { SlRefresh } from "react-icons/sl";
import { LiaTimesSolid } from "react-icons/lia";
// #endregion

// #region Type Definition
type NotionPageSelectProps = Partial<BoxProps> & {
  search: Partial<InputBaseProps>;
  list: Partial<BoxProps>;
  refresh: Partial<ButtonIconProps>;
  clear: Partial<IconProps>;
};
// #endregion

// #region NotionPageSelectComponent
const NotionPageSelect: React.FC<NotionPageSelectProps> = (props) => {
  const { search, list, refresh, clear, ...rootProps } = props;

  return (
    <Box
      tag={"div"}
      css={{ width: "340px", paddingTop: "8px", paddingBottom: "24px" }}
      {...rootProps}
    >
      <Box
        tag={"div"}
        css={{
          padding: "4px",
          boxShadow:
            "rgba(0, 0, 0, 0.02) 0px 1px 3px 0px , rgba(27, 31, 35, 0.15) 0px 0px 0px 1px",
          marginBottom: "14px",
          borderRadius: "28px",
          display: "flex",
          alignItems: "center",
          ".mui-dark &": {
            boxShadow:
              "rgba(253, 253, 253, 0.02) 0px 1px 3px 0px  , rgba(254, 254, 254, 0.15) 0px 0px 0px 1px ",
          },
        }}
      >
        <Icon
          color={"inherit"}
          fontSize={"inherit"}
          icon={CiSearch}
          css={{ fontSize: "32px" }}
        />
        <InputBase
          inputName={"search"}
          color={"primary"}
          type={"text"}
          aria-label={"Input Base Mui"}
          placeholder={"Recherche"}
          autoFocus={true}
          disabled={false}
          fullWidth={true}
          maxRows={1}
          minRows={1}
          rows={1}
          multiline={false}
          readOnly={false}
          required={false}
          size={"medium"}
          css={{ paddingLeft: "16px" }}
          {...search}
        />
        <Icon
          color={"inherit"}
          fontSize={"inherit"}
          icon={LiaTimesSolid}
          css={{ fontSize: "23px", marginRight: "4px", cursor: "pointer" }}
          {...clear}
        />
        <ButtonIcon
          type={"button"}
          disabled={false}
          disableFocusRipple={false}
          disableRipple={false}
          color={"primary"}
          Icon={
            <Icon color={"inherit"} fontSize={"inherit"} icon={SlRefresh} />
          }
          {...refresh}
        />
      </Box>
      <Box
        tag={"div"}
        css={{ padding: "2px", maxHeight: "50vh", overflowY: "auto" }}
        {...list}
      />
    </Box>
  );
};

export default NotionPageSelect;
// #endregion
