/** Build with repleek */
// #region Import
import React from "react";

import {
  Box,
  Container,
  ContainerProps,
  SelectField,
  SelectFieldProps,
  TextField,
  TextFieldProps,
} from "@repleek/mui";
// #endregion

// #region Type Definition
type UserFormProps = Partial<ContainerProps> & {
  email: Partial<TextFieldProps>,
  type: Partial<SelectFieldProps>,
  workspaces: Partial<SelectFieldProps>,
};
// #endregion

// #region UserFormComponent
const UserForm: React.FC<UserFormProps> = (props) => {
  const { email, type, workspaces, ...rootProps } = props;

  return (
    <Container
      maxWidth={"sm"}
      disableGutters={false}
      fixed={false}
      css={{ paddingTop: "16px" }}
      {...rootProps}
    >
      <Box
        tag={"div"}
        css={{ gap: "8px", marginBottom: "16px", display: "flex" }}
      >
        <TextField
          inputName={"firstName"}
          label={"Prénom"}
          variant={"outlined"}
          color={"primary"}
          type={"text"}
          aria-label={"Input Base Mui"}
          autoFocus={false}
          disabled={false}
          fullWidth={true}
          maxRows={1}
          minRows={1}
          rows={1}
          multiline={false}
          readOnly={false}
          required={true}
          size={"small"}
        />
        <TextField
          inputName={"lastName"}
          label={"Nom"}
          variant={"outlined"}
          color={"primary"}
          type={"text"}
          aria-label={"Input Base Mui"}
          autoFocus={false}
          disabled={false}
          fullWidth={true}
          maxRows={1}
          minRows={1}
          rows={1}
          multiline={false}
          readOnly={false}
          required={true}
          size={"small"}
        />
      </Box>
      <TextField
        inputName={"email"}
        label={"Email"}
        variant={"outlined"}
        color={"primary"}
        type={"email"}
        aria-label={"Input Base Mui"}
        autoFocus={false}
        disabled={false}
        fullWidth={true}
        maxRows={1}
        minRows={1}
        rows={1}
        multiline={false}
        readOnly={false}
        required={true}
        size={"small"}
        css={{ marginBottom: "16px" }}
        {...email}
      />
      <SelectField
        options={[{ label: "Default", value: "default" }]}
        inputName={"type"}
        label={"Role"}
        variant={"outlined"}
        color={"primary"}
        aria-label={"Input Base Mui"}
        autoFocus={false}
        disabled={false}
        fullWidth={true}
        multiple={false}
        readOnly={false}
        required={true}
        size={"small"}
        {...type}
      />
      <SelectField
        options={[{ label: "Default", value: "default" }]}
        inputName={"workspaces"}
        label={"Workspaces"}
        variant={"outlined"}
        color={"primary"}
        aria-label={"Input Base Mui"}
        autoFocus={false}
        disabled={false}
        fullWidth={true}
        multiple={false}
        readOnly={false}
        required={true}
        size={"small"}
        css={{ marginTop: "16px" }}
        {...workspaces}
      />
    </Container>
  );
};

export default UserForm;
// #endregion
