import React, { Suspense, useCallback } from "react";
import { useFormContext } from "react-hook-form";
import { Modal, PistacheLoader } from "@/components/layout/modals";
import { Accountnotion } from "@/modules/strapi-sdk/lib/interfaces";
import { Box } from "@mui/material";
import SocialPageList from "./SocialPageList";

type NotionAccountsProps = {
  open: boolean;
  onClose: () => void;
};

const NotionSelect: React.FC<NotionAccountsProps> = (props) => {
  const { onClose, open } = props;

  const { setValue, getValues } = useFormContext();

  const onLink = useCallback(
    (data?: Accountnotion) => {
      if (data) {
        // NOTION ID
        setValue("notion_id", data.notion_id);

        // NOTION LOGO
        setValue(
          "url",
          data.icon?.type
            ? (["file", "external"].includes(data.icon.type) &&
                data.icon.value) ||
                ""
            : ""
        );

        // NOTION URL
        setValue("notion_url", data.notion_url);

        // UPDATE RS
        const rs = ["facebook", "instagram", "linkedin", "tiktok"];
        setValue(
          "configuration",
          rs.reduce((acc, curr) => {
            acc[curr] = !!data.reseauxInclus?.find(
              ({ value }) => value === curr
            );
            return acc;
          }, {} as Record<string, boolean>)
        );

        // UPDATE NAME
        if (data.name) setValue("name", data.name);

        //  UPDATE EMAIL
        const currentEmail = getValues().email;
        if (data.email && !currentEmail) setValue("email", data.email);
        onClose();
      } else {
        setValue("notion_id", "");
        setValue("url", null);
        setValue("notion_url", null);
      }
    },
    [getValues, onClose, setValue]
  );

  return (
    <Modal open={open} onClose={onClose} noHeader type="box" noActions>
      <Suspense
        fallback={
          <Box sx={{ minWidth: "340px" }}>
            <PistacheLoader />
          </Box>
        }>
        <SocialPageList onLink={onLink} />
      </Suspense>
    </Modal>
  );
};

export default NotionSelect;
