import React, { useCallback, useEffect, useMemo, useState } from "react";
import { Modal } from "@/components/layout";
import { User } from "@/modules/strapi-sdk/lib/interfaces";
import UserForm from "./UserForm";
import { useStrapi } from "@/providers/StrapiProvider";
import { useRecoilValue } from "recoil";
import { authState } from "@/store/auth";
import { FaRegUserCircle } from "react-icons/fa";
import { userTypeLabel } from "@/store/user";
import { toast } from "react-toastify";
import { myWorkspaces } from "@/store/workspace";

type Props = {
  id?: number;
  wkId?: number;
  open: boolean;
  onClose: () => void;
  onSave?: () => void;
};

const UserModal: React.FC<Props> = (props) => {
  const { id, open, wkId, onClose, onSave } = props;
  const me = useRecoilValue(authState);
  const workspaces = useRecoilValue(myWorkspaces);

  const [strapi, { loading }] = useStrapi();
  const [user, setUser] = useState<User | undefined>(undefined);
  const defaultValues = useMemo(() => {
    if (user)
      return {
        ...user,
        workspaces: (user.workspaces || [])?.map(({ id }) => id),
      };

    return {
      workspaces: [wkId],
    };
  }, [user, wkId]);

  const userTypes = useMemo(
    () =>
      Object.entries(userTypeLabel).map(([value, label]) => ({ value, label })),
    []
  );

  const workspacesOptions = useMemo(() => {
    let wks = workspaces || [];

    if (user?.workspaces?.length) wks = [...wks, ...user.workspaces];

    return Array.from(
      new Map(
        wks.map(({ id, name }) => [id, { value: id, label: name }])
      ).values()
    ) as { value: number; label: string }[];
  }, [user?.workspaces, workspaces]);

  const onSubmit = useCallback(
    async (data: User) => {
      try {
        // cm can create/update user type ["cm", "client", "commercial"]
        if (
          me?.type === "cm" &&
          user?.type !== data?.type &&
          !["cm", "client", "commercial"].includes(data.type as any)
        )
          return toast.error(
            "Vous n'avez pas la permission de créer ou de mettre à jour ce type d'utilisateur."
          );

        if (!data.workspaces?.length)
          return toast.error("Veuillez sélectionner un espace de travail.");

        if (user?.id) {
          // update user
          const res = await strapi?.update("me/users", user.id, data);
          if (!res?.data?.id) return;
        } else {
          // create user
          const res = await strapi?.create("me/users", data);
          if (!res?.data?.id) return;
        }
        onSave?.();
        onClose();
      } catch (error) {}
    },
    [me?.type, onClose, onSave, strapi, user?.id, user?.type]
  );

  useEffect(() => {
    if (id) {
      strapi
        ?.findOne("me/users", id, {
          populate: {
            workspaces: true,
          },
        })
        .then((res) => setUser(res));
    }
    return () => {
      setUser(undefined);
    };
  }, [id, strapi]);

  return (
    <Modal
      type="form"
      open={open}
      loading={(!!id && !user) || loading}
      title={id ? "Mise à jour utilisateur" : "Création utilisateur"}
      form={{ defaultValues, onSubmit }}
      icon={{ icon: FaRegUserCircle }}
      onClose={onClose}
      onConfirm={() => {}}
    >
      <UserForm
        email={{ inputName: "email" }}
        type={{
          inputName: "type",
          options: userTypes,
          disabled: me?.type && !["admin", "cm"].includes(me.type),
        }}
        workspaces={{
          multiple: true,
          options: workspacesOptions,
        }}
      />
    </Modal>
  );
};

export default UserModal;
