import React, { useCallback, useMemo, useState } from "react";
import { useRecoilRefresher_UNSTABLE, useRecoilValue } from "recoil";
import { AdUser, UserCard } from "@/components";
import { accountSelector } from "@/store/accounts";
import { Box } from "@mui/material";
import { userTypeLabel } from "@/store/user";
import { useStrapi } from "@/providers/StrapiProvider";
import UserSelectModal from "@/components/features/user/UserSelectModal";
import { PistacheLoader } from "@/components/layout";

type Props = { id: number };

const UserAccess: React.FC<Props> = (props) => {
  const { id } = props;
  const [strapi, { loading }] = useStrapi();
  const account = useRecoilValue(accountSelector(id));
  const refreshAccount = useRecoilRefresher_UNSTABLE(accountSelector(id));
  const [open, setOpen] = useState(false);

  const users = useMemo(
    () =>
      account?.users_access?.map(
        ({ id, avatar, type, email, firstName, lastName }) => ({
          id,
          name: [firstName, lastName].join(" "),
          email,
          type: type ? userTypeLabel[type] : "",
          avatar: avatar?.url,
          initial: `${firstName?.[0] || email?.[0]}${
            lastName?.[0] || email?.[1]
          }`,
        })
      ) || [],
    [account]
  );

  const selectedUsers = useMemo(
    () => users.map(({ id }) => id as number),
    [users]
  );

  const onDeleteUser = useCallback(
    async (userId: number) => {
      await strapi?.update("me/accounts", id, {
        users_access: users
          .filter(({ id }) => id !== userId)
          .map(({ id }) => ({ id })),
      });
      refreshAccount();
    },
    [id, refreshAccount, strapi, users]
  );

  const onAdUser = useCallback(
    async (userId: number) => {
      setOpen(false);
      await strapi?.update("me/accounts", id, {
        users_access: [...users.map(({ id }) => ({ id })), { id: userId }],
      });
      refreshAccount();
    },
    [id, refreshAccount, strapi, users]
  );

  return (
    <>
      {loading ? (
        <PistacheLoader css={{ height: "100%" }} />
      ) : (
        <Box sx={{ display: "flex", flexWrap: "wrap", gap: "8px" }}>
          {users.map(({ id, name, email, type, avatar, initial }) => (
            <UserCard
              key={id}
              avatar={{
                src: avatar,
                initial,
              }}
              name={{ text: name }}
              email={{ text: email }}
              type={{ text: type }}
              deleteBtn={{
                onClick: () => id && onDeleteUser(id),
                disabled: loading,
              }}
            />
          ))}
          <AdUser onClick={() => setOpen(true)} />
        </Box>
      )}
      <UserSelectModal
        open={open}
        onClose={() => setOpen(false)}
        selectedUsers={selectedUsers}
        onChange={onAdUser}
      />
    </>
  );
};

export default UserAccess;
