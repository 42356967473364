/** Build with repleek */
// #region Import
import React from "react";

import {
  Box,
  BoxProps,
  Chip,
  ChipProps,
  Fragment,
  FragmentProps,
  Icon,
  IconProps,
  Image,
  ImageProps,
  Typography,
  TypographyProps,
} from "@repleek/mui";
import { GoDotFill } from "react-icons/go";
import { LiaTimesSolid } from "react-icons/lia";
// #endregion

// #region Type Definition
type PreviewPostProps = Partial<BoxProps> & {
  name: TypographyProps & {},
  socialTab: Partial<BoxProps>,
  status: Partial<ChipProps>,
  body: Partial<BoxProps>,
  close: Partial<IconProps>,
  notion: Partial<ImageProps>,
  hootsuite: Partial<ImageProps>,
  skeleton: Partial<FragmentProps>,
};
// #endregion

// #region PreviewPostComponent
const PreviewPost: React.FC<PreviewPostProps> = (props) => {
  const {
    name,
    socialTab,
    status,
    body,
    close,
    notion,
    hootsuite,
    skeleton,
    ...rootProps
  } = props;

  return (
    <Box
      tag={"div"}
      css={{
        padding: "12px",
        maxWidth: "460px",
        userSelect: "none",
        "@media (max-width: 700px)": { padding: "8px" },
      }}
      {...rootProps}
    >
      <Box
        tag={"div"}
        css={{
          padding: "8px",
          gap: "8px",
          marginBottom: "8px",
          display: "flex",
          justifyContent: "space-between",
          alignItems: "center",
        }}
      >
        <Box
          tag={"div"}
          css={{ gap: "8px", display: "flex", alignItems: "center" }}
        >
          <Chip
            label={"Chip label"}
            avatar={false}
            icon={true}
            clickable={false}
            color={"default"}
            variant={"filled"}
            Icon={
              <Icon
                color={"inherit"}
                fontSize={"inherit"}
                icon={GoDotFill}
                className={
                  "MuiChip-icon MuiChip-iconMedium MuiChip-iconColorDefault"
                }
              />
            }
            {...status}
          />
          <Image
            alt={"Image description for better SEO"}
            src={"/images/logo-notion.svg"}
            dynamic={true}
            css={{
              padding: "6px",
              width: "38px",
              backgroundColor: "rgba(255,255,255, 1)",
              borderWidth: "1px",
              borderStyle: "solid",
              borderColor: "rgba(192,192,192, 1)",
              borderRadius: "36px",
              cursor: "pointer",
            }}
            {...notion}
          />
          <Image
            alt={"Image description for better SEO"}
            src={"/images/logo-hootsuite.svg"}
            dynamic={true}
            css={{
              padding: "2px",
              width: "38px",
              backgroundColor: "rgba(255,255,255, 1)",
              borderWidth: "1px",
              borderStyle: "solid",
              borderColor: "rgba(192,192,192, 1)",
              borderRadius: "36px",
              cursor: "pointer",
            }}
            {...hootsuite}
          />
          <Fragment {...skeleton} />
        </Box>
        <Icon
          color={"inherit"}
          fontSize={"inherit"}
          icon={LiaTimesSolid}
          css={{ fontSize: "24px", cursor: "pointer" }}
          {...close}
        />
      </Box>
      <Typography
        variant={"h3"}
        text={"Mon titre ici"}
        gutterBottom={false}
        noWrap={false}
        css={{
          fontSize: "18px",
          flexGrow: "1",
          marginBottom: "14px",
          textAlign: "center",
          paddingLeft: "8px",
          paddingRight: "8px",
          "@media (max-width: 700px)": { fontSize: "16px", display: "none" },
        }}
        {...name}
      />
      <Box
        tag={"div"}
        css={{
          display: "flex",
          justifyContent: "space-around",
          "@media (max-width: 700px)": {
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
          },
        }}
      >
        <Box
          tag={"div"}
          css={{
            marginBottom: "16px",
            gap: "8px",
            paddingLeft: "12px",
            paddingRight: "16px",
            display: "flex",
            flexDirection: "column",
            ".active": {
              boxShadow: "rgba(246, 75, 247, 0.3) 0px 0px 0px 3px ",
            },
            "@media (max-width: 700px)": {
              display: "flex",
              flexDirection: "row",
            },
          }}
          {...socialTab}
        />
        <Box
          tag={"div"}
          css={{
            width: "331px",
            height: "600px",
            boxShadow:
              "rgba(50, 50, 93, 0.25) 0px 13px 27px -5px , rgba(0, 0, 0, 0.3) 0px 8px 16px -8px",
            overflowY: "auto",
            position: "relative",
            paddingLeft: "12px",
            paddingRight: "12px",
            paddingTop: "33px",
            paddingBottom: "12px",
            borderRadius: "26px",
            background:
              "url(/images/mock-iphone.svg) center / contain no-repeat scroll , linear-gradient(90deg, RGBA(243, 244, 246, 1) 0%, rgba(243,244,246,1) 100%)",
            ".mui-dark &": {
              background:
                "url(/images/mock-iphone.svg) center / cover no-repeat scroll",
            },
          }}
        >
          <Box
            tag={"div"}
            className={"hide-scrollbar"}
            css={{
              height: "100%",
              borderBottomLeftRadius: "18px",
              borderBottomRightRadius: "18px",
              overflow: "hidden",
              overflowY: "auto",
            }}
            {...body}
          />
        </Box>
      </Box>
    </Box>
  );
};

export default PreviewPost;
// #endregion
