import React, { Suspense, useCallback } from "react";
import { Modal, PistacheLoader } from "@/components/layout/modals";
import {
  Account,
  HootsuiteSocialProfile,
} from "@/modules/strapi-sdk/lib/interfaces";
import SocialPageList from "./SocialPageList";
import { useStrapi } from "@/providers/StrapiProvider";

type NotionAccountsProps = {
  account: Account;
  accountType: HootsuiteSocialProfile["type"] | undefined;
  onClose: () => void;
  refreshAccount: () => void;
};

const HootsuiteSelect: React.FC<NotionAccountsProps> = (props) => {
  const { onClose, refreshAccount, accountType, account } = props;

  const [strapi, { loading }] = useStrapi();

  const onLink = useCallback(
    async (data?: HootsuiteSocialProfile) => {
      if (!account?.id || !accountType) return;

      await strapi?.post(
        "accounts/:accountId/link-social-page/:provider",
        {
          ":accountId": account.id,
          ":provider": accountType,
        },
        { ...account[`${accountType}_account`], ...data }
      );
      onClose();
      refreshAccount();
    },
    [account, accountType, onClose, refreshAccount, strapi]
  );

  return (
    <Modal open={!!accountType} onClose={onClose} noHeader type="box" noActions>
      <Suspense fallback={<PistacheLoader css={{ minWidth: "340px" }} />}>
        {loading ? (
          <PistacheLoader css={{ minWidth: "340px" }} />
        ) : (
          <SocialPageList
            onLink={onLink}
            workspaceId={account.workspace?.id || 0}
            type={accountType}
          />
        )}
      </Suspense>
    </Modal>
  );
};

export default HootsuiteSelect;
