import { NotFound, WorkspaceDetail } from "@/components";
import Layout, { PistacheLoader } from "@/components/layout";
import { workspaceById } from "@/store/workspace";
import React, { Suspense } from "react";
import { useParams } from "react-router-dom";
import { useRecoilValue } from "recoil";
import Informations from "./informations";
import { MakeRequired } from "@/interfaces";
import { Workspace as WorkspaceType } from "@/modules/strapi-sdk/lib/interfaces";
import WorkspaceForm from "@/components/features/workspace/WorkspaceForm";
import Accounts from "./accounts";
import Users from "./users";

const Workspace: React.FC = () => {
  return (
    <Layout title="Pistache - workspace" mode="ADMIN">
      <Suspense fallback={<PistacheLoader />}>
        <Component />
      </Suspense>
    </Layout>
  );
};

const Component: React.FC = () => {
  const { page, id } = useParams() as {
    page: "informations" | "users" | "accounts";
    id: string;
  };

  const workspace = useRecoilValue(workspaceById(parseInt(id)));
  if (!workspace?.id) return <NotFound />;

  return (
    <>
      <WorkspaceDetail
        avatar={{
          src: workspace.avatar?.url,
          alt: workspace.name,
          initial: workspace.name,
        }}
        editBtn={{ href: `?action=edit` }}
        name={{ text: workspace.name }}
        informations={{
          variant: page === "informations" ? "contained" : "text",
          href: `/workspaces/${id}/informations`,
        }}
        users={{
          variant: page === "users" ? "contained" : "text",
          href: `/workspaces/${id}/users`,
        }}
        accounts={{
          variant: page === "accounts" ? "contained" : "text",
          href: `/workspaces/${id}/accounts`,
        }}
      />

      {page === "informations" && (
        <Informations
          workspace={workspace as MakeRequired<WorkspaceType, "id">}
        />
      )}

      {page === "accounts" && <Accounts workspace={workspace} />}

      {page === "users" && <Users workspace={workspace} />}

      <WorkspaceForm workspace={workspace} />
    </>
  );
};

export default Workspace;
