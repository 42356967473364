import React, { useMemo } from "react";
import { Box, Collapse } from "@mui/material";
import TagField from "@/components/fields/TagField";

export type CollectionFilter =
  | {
      type: "tag";
      label: string;
      name: string;
      options: { label: string; value: string | number }[];
    }
  | {
      type: "toggle";
      label: string;
      name: string;
      options: { label: string; value: boolean }[];
    };

type Props = {
  open: boolean;
  filters: CollectionFilter[];
  value: any;
  onChange: (value: any) => void;
};

const CollectionsFilter: React.FC<Props> = (props) => {
  const { open, filters, value, onChange } = props;

  const fields = useMemo(
    () =>
      filters.map(({ type, label, name, options }) => {
        switch (type) {
          case "tag":
            return (
              <TagField
                key={name}
                label={label}
                value={value?.[name] || []}
                options={options}
                onChange={(value) => onChange({ [name]: value })}
                multiple
              />
            );

          case "toggle":
            return (
              <TagField
                key={name}
                label={label}
                value={value?.[name] || []}
                options={options}
                onChange={(value) => onChange({ [name]: value })}
                multiple={false}
              />
            );

          default:
            return <p key={name}>Unknown Tag Field</p>;
        }
      }),
    [filters, onChange, value]
  );

  return (
    <Collapse in={open}>
      <Box sx={{ display: "flex", gap: "8px" }}>{fields}</Box>
    </Collapse>
  );
};

export default CollectionsFilter;
