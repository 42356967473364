import React, { Suspense, useCallback, useMemo } from "react";
import { NewAccountForm } from "../../new-account";
import { NavLink } from "react-router-dom";
import { LayoutWithSidebar } from "@/components";
import { Button } from "@mui/material";
import ReportingPage from "../reporting";
import { PistacheLoader } from "@/components/layout";
import Planning from "../planning";
import Leads from "../leads";
import GoogleBusiness from "../gmb";
import Insight from "../insight";
import UserAccess from "../user-access";
import { Post } from "@/modules/strapi-sdk/lib/interfaces";
import { useNavigate } from "@repleek/mui";

const Informations: React.FC<{
  id: string | number;
  page?: string;
  type?: string;
}> = (props) => {
  const { id, page, type } = props;

  const { navigate } = useNavigate();

  const onSelectPost = useCallback(
    (post?: Post) => {
      navigate?.(
        !post ? `/account/${id}/planning` : `/account/${id}/planning/${post.id}`
      );
    },
    [id, navigate]
  );

  const childrenWithLayout = useMemo(() => {
    // cehck if account is linked with a gmb
    if (!page) return <NewAccountForm />;
    switch (type) {
      case "insight":
        return <Insight id={id as string} />;
      case "access":
        return <UserAccess id={id as number} />;
    }
  }, [id, page, type]);

  const childrenWithoutLayout = useMemo(() => {
    switch (page) {
      case "reporting":
        return <ReportingPage id={id as string} />;
      case "planning":
        return (
          <Suspense
            fallback={
              <PistacheLoader css={{ height: "calc(100vh - 110px)" }} />
            }>
            <Planning
              id={id as string}
              onSelectPost={onSelectPost}
              postId={type}
            />
          </Suspense>
        );
      case "leads":
        return <Leads id={id as string} />;
      case "gmb":
        return <GoogleBusiness />;
    }
  }, [id, onSelectPost, page, type]);

  const menu = [
    {
      label: "Mise à jour",
      href: `/account/${id}`,
    },
    {
      label: "Statistiques",
      href: `/account/${id}/informations/insight`,
    },
    {
      label: "Accès",
      href: `/account/${id}/informations/access`,
    },
  ];

  if (childrenWithoutLayout) return childrenWithoutLayout;

  return (
    <LayoutWithSidebar
      tabMenu={{
        children: (
          <>
            {menu.map(({ href, label }) => (
              <NavLink key={href} to={href} end>
                {({ isActive }) => (
                  <Button
                    sx={{
                      borderColor: "transparent",
                      justifyContent: "flex-start",
                      padding: "8px 16px",
                      borderRadius: "10px",
                      boxShadow: "none",
                      ...(isActive && {
                        border: "1px solid",
                      }),
                      mb: 0.5,
                      ":hover": {
                        boxShadow: "none",
                      },
                    }}
                    fullWidth
                    color="secondary"
                    size="small"
                    variant="outlined">
                    {label}
                  </Button>
                )}
              </NavLink>
            ))}
          </>
        ),
      }}
      tabContent={{
        children: (
          <Suspense fallback={<PistacheLoader css={{ height: "100%" }} />}>
            {childrenWithLayout}
          </Suspense>
        ),
      }}
      maxWidth="lg"
    />
  );
};

export default Informations;
