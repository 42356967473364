import { localesQuery } from "@/store/i18n";
import { useCallback, useMemo } from "react";
import { useRecoilValue } from "recoil";

export const useTranslate = (key: string) => {
  const locales = useRecoilValue(localesQuery);
  return useMemo(() => locales?.[key] || key, [key, locales]);
};

export const useI18n = () => {
  const locales = useRecoilValue(localesQuery);
  return useCallback(
    (key: string) => {
      return locales?.[key] || key;
    },
    [locales]
  );
};
