import React, { useMemo } from "react";
import { Breadcrumbs, Chip, Typography } from "@mui/material";
import { useNavigate } from "@repleek/mui";
import { useRecoilValue } from "recoil";
import { layoutState } from "@/store/layout";
import { FaCaretRight } from "react-icons/fa6";
import { useLocation } from "react-router-dom";
import { getPageMetaData } from "@/pages";

const BreadCrumb: React.FC = () => {
  const { navigate } = useNavigate();
  const { pathname } = useLocation();
  const { breadcrumbs } = useRecoilValue(layoutState);

  const list = useMemo(() => {
    const metadata = getPageMetaData(pathname);
    if (!metadata) return breadcrumbs;
    return [metadata, ...(breadcrumbs || [])];
  }, [breadcrumbs, pathname]);

  return (
    <Breadcrumbs aria-label="breadcrumb" separator={<FaCaretRight />}>
      {list?.map(
        ({ label, icon: Icon, href }, index) =>
          label &&
          (index ? (
            <Chip
              key={label}
              icon={Icon && <Icon fontSize="small" />}
              label={label}
              variant="outlined"
              {...(href
                ? {
                    onClick: () => navigate?.(href),
                    sx: { pl: 0.5 },
                  }
                : {
                    sx: { pl: 1, textTransform: "uppercase", border: "none" },
                  })}
            />
          ) : (
            <Typography
              variant="h1"
              key={label}
              sx={{
                fontSize: "18px",
                display: "flex",
                alignItems: "center",
                gap: "12px",
                svg: {
                  fontSize: "32px",
                },
              }}
            >
              {Icon && <Icon />}
              {label}
            </Typography>
          ))
      )}
    </Breadcrumbs>
  );
};

export default BreadCrumb;
