/** Build with repleek */
// #region Import
import React from "react";

import {
  Avatar,
  AvatarProps,
  Box,
  BoxProps,
  Icon,
  Typography,
  TypographyProps,
} from "@repleek/mui";
import { BiLike } from "react-icons/bi";
import { FaRegComment } from "react-icons/fa6";
import { IoIosPaperPlane } from "react-icons/io";
import { HiDotsVertical } from "react-icons/hi";
import { IoCloseSharp } from "react-icons/io5";
import { TbRefresh } from "react-icons/tb";
// #endregion

// #region Type Definition
type LinkedinSkinProps = Partial<BoxProps> & {
  avatar: Partial<AvatarProps>,
  name: TypographyProps & {},
  description: TypographyProps & {},
  tags: Partial<BoxProps>,
  media: Partial<BoxProps>,
};
// #endregion

// #region LinkedinSkinComponent
const LinkedinSkin: React.FC<LinkedinSkinProps> = (props) => {
  const { avatar, name, description, tags, media, ...rootProps } = props;

  return (
    <Box
      tag={"div"}
      css={{
        maxWidth: "330px",
        backgroundColor: "rgba(255,255,255, 1)",
        boxShadow: "rgba(0, 0, 0, 0.1) 0px 4px 12px",
        marginBottom: "16px",
        paddingTop: "8px",
        ".mui-dark &": { backgroundColor: "rgba(30, 28, 39, 1)" },
      }}
      {...rootProps}
    >
      <Box
        tag={"div"}
        css={{
          gap: "12px",
          paddingLeft: "12px",
          paddingRight: "12px",
          paddingTop: "8px",
          paddingBottom: "8px",
          display: "flex",
          alignItems: "center",
        }}
      >
        <Avatar variant={"square"} component={"div"} {...avatar} />
        <Typography
          variant={"h4"}
          text={"Mon titre ici"}
          gutterBottom={false}
          noWrap={false}
          css={{ fontSize: "14px", flexGrow: "1" }}
          {...name}
        />
        <Icon color={"inherit"} fontSize={"inherit"} icon={HiDotsVertical} />
        <Icon color={"inherit"} fontSize={"inherit"} icon={IoCloseSharp} />
      </Box>
      <Box
        tag={"div"}
        css={{
          padding: "8px",
          paddingLeft: "12px",
          paddingRight: "12px",
          paddingTop: "8px",
          paddingBottom: "8px",
        }}
      >
        <Typography
          variant={"body1"}
          text={"Mon titre ici"}
          gutterBottom={false}
          noWrap={false}
          css={{ fontSize: "14px", lineHeight: "16px" }}
          {...description}
        />
        <Box
          tag={"div"}
          css={{
            fontSize: "14px",
            lineHeight: "14px",
            paddingTop: "8px",
            paddingBottom: "8px",
            gap: "8px",
            display: "flex",
            flexWrap: "wrap",
          }}
          {...tags}
        />
      </Box>
      <Box tag={"div"} {...media} />
      <Box
        tag={"div"}
        css={{
          padding: "12px",
          display: "flex",
          justifyContent: "space-between",
        }}
      >
        <Box
          tag={"div"}
          css={{
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
          }}
        >
          <Icon color={"inherit"} fontSize={"inherit"} icon={BiLike} />
          <Typography
            variant={"body1"}
            text={"J'aime"}
            gutterBottom={false}
            noWrap={false}
            css={{ fontSize: "14px" }}
          />
        </Box>
        <Box
          tag={"div"}
          css={{
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
          }}
        >
          <Icon color={"inherit"} fontSize={"inherit"} icon={FaRegComment} />
          <Typography
            variant={"body1"}
            text={"Commenter"}
            gutterBottom={false}
            noWrap={false}
            css={{ fontSize: "14px" }}
          />
        </Box>
        <Box
          tag={"div"}
          css={{
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
          }}
        >
          <Icon color={"inherit"} fontSize={"inherit"} icon={TbRefresh} />
          <Typography
            variant={"body1"}
            text={"Republier"}
            gutterBottom={false}
            noWrap={false}
            css={{ fontSize: "14px" }}
          />
        </Box>
        <Box
          tag={"div"}
          css={{
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
          }}
        >
          <Icon color={"inherit"} fontSize={"inherit"} icon={IoIosPaperPlane} />
          <Typography
            variant={"body1"}
            text={"Envoyer"}
            gutterBottom={false}
            noWrap={false}
            css={{ fontSize: "14px" }}
          />
        </Box>
      </Box>
    </Box>
  );
};

export default LinkedinSkin;
// #endregion
