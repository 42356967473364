/** Build with repleek */
// #region Import
import React from "react";

import { Box, BoxProps, Icon } from "@repleek/mui";
import { FaPlus } from "react-icons/fa6";
// #endregion

// #region Type Definition
type AdUserProps = Partial<BoxProps> & {};
// #endregion

// #region AdUserComponent
const AdUser: React.FC<AdUserProps> = (props) => {
  const { ...rootProps } = props;

  return (
    <Box
      tag={"div"}
      css={{
        width: "88px",
        minHeight: "88px",
        boxShadow:
          "rgba(0, 0, 0, 0.02) 0px 1px 3px 0px , rgba(27, 31, 35, 0.15) 0px 0px 0px 1px",
        borderRadius: "14px",
        cursor: "pointer",
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        ".mui-dark &": {
          boxShadow:
            "rgba(255, 255, 255, 0.02) 0px 1px 3px 0px  , rgba(248, 248, 248, 0.15) 0px 0px 0px 1px ",
        },
      }}
      {...rootProps}
    >
      <Icon
        color={"inherit"}
        fontSize={"inherit"}
        icon={FaPlus}
        css={{ fontSize: "32px" }}
      />
    </Box>
  );
};

export default AdUser;
// #endregion
