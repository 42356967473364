/** Build with repleek */
// #region Import
import React from "react";

import {
  Avatar,
  AvatarProps,
  Box,
  BoxProps,
  Icon,
  Typography,
  TypographyProps,
} from "@repleek/mui";
import { FaCaretDown } from "react-icons/fa6";
// #endregion

// #region Type Definition
type AccountSelectedProps = Partial<BoxProps> & {
  avatar: Partial<AvatarProps>,
  name: TypographyProps & {},
};
// #endregion

// #region AccountSelectedComponent
const AccountSelected: React.FC<AccountSelectedProps> = (props) => {
  const { avatar, name, ...rootProps } = props;

  return (
    <Box
      tag={"div"}
      css={{
        width: "190px",
        color: "rgba(255,255,255, 1)",
        boxShadow:
          "rgba(0, 0, 0, 0.02) 0px 1px 3px 0px , rgba(27, 31, 35, 0.15) 0px 0px 0px 1px",
        overflowY: "hidden",
        gap: "8px",
        paddingLeft: "4px",
        paddingRight: "8px",
        paddingTop: "4px",
        paddingBottom: "4px",
        borderRadius: "32px",
        cursor: "pointer",
        background:
          "linear-gradient(90deg, rgb(142, 45, 226) 0%, rgb(74, 0, 224) 100%)",
        display: "flex",
        alignItems: "center",
      }}
      {...rootProps}
    >
      <Avatar
        variant={"circular"}
        component={"div"}
        css={{ backgroundColor: "rgba(255,255,255, 1)" }}
        {...avatar}
      />
      <Typography
        variant={"h3"}
        text={"Mon titre ici"}
        gutterBottom={false}
        noWrap={false}
        css={{ fontSize: "14px", flexGrow: "1" }}
        {...name}
      />
      <Icon color={"inherit"} fontSize={"inherit"} icon={FaCaretDown} />
    </Box>
  );
};

export default AccountSelected;
// #endregion
