import AccountItemSelect from "@/components/layout/AccountItemSelect";
import AccountSelected from "@/components/layout/AccountSelected";
import { accountSelected, accountsUserState } from "@/store/auth";
import { Box, Popover, Skeleton } from "@mui/material";
import React, { Suspense, useEffect, useMemo, useState } from "react";
import { useRecoilState, useRecoilValue } from "recoil";

const SelectAccount: React.FC = () => {
  return (
    <Suspense
      fallback={
        <Skeleton
          variant="rounded"
          width={190}
          height={42}
          sx={{ borderRadius: "32px" }}
        />
      }
    >
      <Component />
    </Suspense>
  );
};

const Component: React.FC = () => {
  const [anchorEl, setAnchorEl] = useState<HTMLDivElement | null>(null);

  const [account, setAccount] = useRecoilState(accountSelected);
  const accounts = useRecoilValue(accountsUserState);

  const filteredAccounts = useMemo(
    () => (accounts || []).filter(({ id }) => account?.id !== id),
    [account?.id, accounts]
  );

  const handleClick = (event: React.MouseEvent<HTMLDivElement>) => {
    if (filteredAccounts.length) setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const open = Boolean(anchorEl);
  const id = open ? "simple-popover" : undefined;

  useEffect(() => {
    if (!account && accounts?.length) {
      setAccount(accounts[0]);
    }
  }, [account, accounts, setAccount]);

  if (!accounts?.length) return null;

  return (
    <>
      <AccountSelected
        avatar={{
          src: account?.url,
        }}
        name={{
          text: account?.name,
        }}
        onClick={handleClick}
      />
      <Popover
        id={id}
        open={open}
        anchorEl={anchorEl}
        onClose={handleClose}
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "center",
        }}
        transformOrigin={{
          vertical: "top",
          horizontal: "center",
        }}
        slotProps={{
          paper: {
            sx: {
              borderRadius: "10px",
              overflow: "hidden",
              mt: 1,
              maxHeight: "480px",
              overflowY: "auto",
            },
          },
        }}
      >
        <Box sx={{ mb: 2, width: 190 }}>
          {filteredAccounts.map(({ id, name, url, ...account }) => (
            <AccountItemSelect
              key={id}
              avatar={{ src: url, initial: name }}
              name={{ text: name }}
              onClick={() => {
                setAccount({ id, name, url, ...account });
                setAnchorEl(null);
              }}
            />
          ))}
        </Box>
      </Popover>
    </>
  );
};

export default SelectAccount;
