import React, { useEffect, useMemo, useRef, useState } from "react";
import { HootsuiteSocialProfile } from "@/modules/strapi-sdk/lib/interfaces";
import { useThrottle } from "@uidotdev/usehooks";
import { useRecoilRefresher_UNSTABLE, useRecoilValue } from "recoil";
import { NotionItem, NotionPageSelect } from "@/components";
import { useWatch } from "react-hook-form";
import {
  hootsuiteSocialProfiles,
  hootsuiteSocialProfilesByType,
} from "@/store/workspace";

type Props = {
  workspaceId: number;
  onLink: (data?: HootsuiteSocialProfile) => void;
  type: HootsuiteSocialProfile["type"];
};

const SocialPageList: React.FC<Props> = (props) => {
  const { onLink, workspaceId, type } = props;

  const [search, setSearch] = useState("");
  const throttledSearch = useThrottle(search, 800);
  const ref = useRef<HTMLInputElement>(null);

  const profile = useWatch({
    name: `${type}_account`,
  }) as HootsuiteSocialProfile;

  const accounts = useRecoilValue(
    hootsuiteSocialProfilesByType({ id: workspaceId, type })
  );
  const refresh = useRecoilRefresher_UNSTABLE(
    hootsuiteSocialProfiles(workspaceId)
  );

  const pagesFilterd = useMemo(
    () =>
      accounts?.filter(({ name }) =>
        name?.toLowerCase().includes(throttledSearch.toLocaleLowerCase())
      ),
    [accounts, throttledSearch]
  );

  useEffect(() => {
    if (ref.current) {
      ref.current.value = "";
    }
  }, []);

  return (
    <NotionPageSelect
      list={{
        children: pagesFilterd?.map((page) => (
          <NotionItem
            key={page.hootsuiteId}
            onClick={() => onLink(page)}
            avatar={{
              src: page.avatarUrl,
              initial: page.name?.substring(0, 2),
            }}
            name={{ text: page.name }}
            selected={{
              hidden: page.hootsuiteId !== profile?.hootsuiteId,
            }}
            email={{ hidden: true }}
          />
        )),
      }}
      search={{
        inputName: "search",
        onKeyUp: (event) => setSearch(event.currentTarget.value),
        ref,
      }}
      refresh={{ onClick: refresh }}
      clear={{}}
    />
  );
};

export default SocialPageList;
