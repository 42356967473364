/** Build with repleek */
// #region Import
import React from "react";

import {
  Avatar,
  AvatarProps,
  Box,
  BoxProps,
  Button,
  ButtonIcon,
  ButtonIconProps,
  ButtonProps,
  Divider,
  Icon,
  Typography,
  TypographyProps,
} from "@repleek/mui";
import { IoArchiveOutline } from "react-icons/io5";
import { HiMiniCog6Tooth } from "react-icons/hi2";
// #endregion

// #region Type Definition
type CollectionCardProps = Partial<BoxProps> & {
  name: TypographyProps & {},
  deleteBtn: Partial<ButtonIconProps>,
  editBtn: Partial<ButtonIconProps>,
  openBtn: ButtonProps & {},
  description: TypographyProps & {},
  avatar: Partial<AvatarProps>,
};
// #endregion

// #region CollectionCardComponent
const CollectionCard: React.FC<CollectionCardProps> = (props) => {
  const {
    name,
    deleteBtn,
    editBtn,
    openBtn,
    description,
    avatar,
    ...rootProps
  } = props;

  return (
    <Box
      tag={"div"}
      className={"bg-white"}
      css={{
        padding: "14px",
        maxWidth: "450px",
        boxShadow:
          "rgba(0, 0, 0, 0.02) 0px 1px 3px 0px , rgba(27, 31, 35, 0.15) 0px 0px 0px 1px",
        overflow: "hidden",
        borderRadius: "8px",
        display: "flex",
        flexDirection: "column",
      }}
      {...rootProps}
    >
      <Box
        tag={"div"}
        css={{
          gap: "12px",
          flexGrow: "1",
          display: "flex",
          alignItems: "center",
        }}
      >
        <Avatar
          variant={"circular"}
          component={"div"}
          css={{ width: "58px", height: "58px", fontSize: "24px" }}
          {...avatar}
        />
        <Box tag={"div"} css={{ flexGrow: "1" }}>
          <Typography
            variant={"h3"}
            text={"Mon titre ici"}
            gutterBottom={false}
            noWrap={false}
            css={{ fontSize: "16px" }}
            {...name}
          />
          <Typography
            variant={"caption"}
            text={"Mon titre ici"}
            gutterBottom={false}
            noWrap={false}
            className={"text-wrap"}
            css={{ fontSize: "14px", lineHeight: "16px", marginTop: "6px" }}
            {...description}
          />
        </Box>
      </Box>
      <Divider
        chip={false}
        flexItem={false}
        light={false}
        orientation={"horizontal"}
        textAlign={"center"}
        variant={"fullWidth"}
      />
      <Box
        tag={"div"}
        css={{
          marginTop: "4px",
          display: "flex",
          justifyContent: "space-between",
          alignItems: "center",
        }}
      >
        <Box tag={"div"} css={{ gap: "8px", display: "inline-flex" }}>
          <ButtonIcon
            type={"button"}
            disabled={false}
            disableFocusRipple={false}
            disableRipple={false}
            size={"small"}
            color={"primary"}
            Icon={
              <Icon
                color={"inherit"}
                fontSize={"inherit"}
                icon={HiMiniCog6Tooth}
              />
            }
            {...editBtn}
          />
          <ButtonIcon
            type={"button"}
            disabled={false}
            disableFocusRipple={false}
            disableRipple={false}
            size={"small"}
            color={"error"}
            Icon={
              <Icon
                color={"inherit"}
                fontSize={"inherit"}
                icon={IoArchiveOutline}
              />
            }
            {...deleteBtn}
          />
        </Box>
        <Button
          text={"Consulter"}
          type={"button"}
          disabled={false}
          disableElevation={false}
          disableFocusRipple={false}
          disableRipple={false}
          fullWidth={false}
          variant={"contained"}
          color={"primary"}
          iconStart={false}
          iconEnd={false}
          css={{
            fontSize: "12px",
            paddingLeft: "16px",
            paddingRight: "16px",
            paddingTop: "4px",
            paddingBottom: "4px",
          }}
          {...openBtn}
        />
      </Box>
    </Box>
  );
};

export default CollectionCard;
// #endregion
