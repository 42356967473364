import React, { Suspense, useCallback } from "react";
import { useRecoilValue } from "recoil";
import Layout, { PistacheLoader } from "@/components/layout";
import PlanningComponent from "../admin/account/planning";
import { accountSelected } from "@/store/auth";
import { Post } from "@/modules/strapi-sdk/lib/interfaces";
import { useNavigate } from "@repleek/mui";
import { useParams } from "react-router-dom";

const Planning: React.FC = () => {
  return (
    <Layout mode="CUSTOMER" title="Pistache - Planning">
      <Suspense
        fallback={<PistacheLoader css={{ height: "calc(100vh - 110px)" }} />}
      >
        <Component />
      </Suspense>
    </Layout>
  );
};

export default Planning;

const Component: React.FC = () => {
  const account = useRecoilValue(accountSelected);
  const { id } = useParams();
  const { navigate } = useNavigate();

  const onSelectPost = useCallback(
    (post?: Post) => {
      navigate?.(!post ? "/planning" : `/planning/${post.id}`);
    },
    [navigate]
  );

  if (!account?.id) return null;

  return (
    <PlanningComponent
      id={account.id.toString()}
      status={["done", "validated", "checked"]}
      onSelectPost={onSelectPost}
      postId={id}
    />
  );
};
