import { Alert } from "@mui/material";
import React, { useMemo } from "react";

type Props = {
  errors: Record<string, string[]>;
  activeTab: string;
};

const ErrorAlert: React.FC<Props> = (props) => {
  const { errors, activeTab } = props;

  const alerts = useMemo(() => errors?.[activeTab] || [], [activeTab, errors]);

  return (
    <>
      {alerts.map((alert) => (
        <Alert
          key={alert}
          severity="error"
          sx={{ m: 1, borderRadius: "8px", background: "#ef5350" }}
          variant="filled"
        >
          {alert}
        </Alert>
      ))}
    </>
  );
};

export default ErrorAlert;
