import Layout from "@/components/layout";
import React from "react";

const Presentation: React.FC = () => {
  return (
    <Layout
      mode="LOGGED_IN"
      body={{ maxWidth: "xl" }}
      topBar={{ hidden: true }}>
      <iframe
        src="https://gamma.app/embed/3ma10q5qzbrmvab"
        style={{
          width: "100%",
          maxWidth: "100%",
          height: "calc(100vh - 8px)",
          border: "none",
        }}
        allow="fullscreen"
        title="À la découverte de Pist'App !!!"></iframe>
    </Layout>
  );
};

export default Presentation;
