import React, { useEffect, useMemo, useRef, useState } from "react";
import { Accountnotion } from "@/modules/strapi-sdk/lib/interfaces";
import { useThrottle } from "@uidotdev/usehooks";
import { useRecoilRefresher_UNSTABLE, useRecoilValue } from "recoil";
import { NotionItem, NotionPageSelect } from "@/components";
import { gmbUnlinkedAccounts } from "@/store/workspace";

type Props = {
  wkId: number;
  onLink: (data?: Accountnotion) => void;
};

const GMBPageList: React.FC<Props> = (props) => {
  const { onLink, wkId } = props;

  const [search, setSearch] = useState("");
  const throttledSearch = useThrottle(search, 800);
  const ref = useRef<HTMLInputElement>(null);

  const accounts = useRecoilValue(gmbUnlinkedAccounts(wkId));
  const refresh = useRecoilRefresher_UNSTABLE(gmbUnlinkedAccounts(wkId));

  const pagesFilterd = useMemo(
    () =>
      accounts?.filter(({ name }) =>
        name?.toLowerCase().includes(throttledSearch.toLocaleLowerCase())
      ),
    [accounts, throttledSearch]
  );

  useEffect(() => {
    if (ref.current) {
      ref.current.value = "";
    }
  }, []);

  return (
    <NotionPageSelect
      list={{
        children: pagesFilterd?.map((page) => (
          <NotionItem
            key={page.id}
            onClick={() => onLink(page)}
            avatar={{
              src: page.picture,
              initial: page.name?.substring(0, 2),
            }}
            name={{ text: page.name }}
            selected={
              {
                //hidden: page.notion_id !== notionId,
              }
            }
            email={{ hidden: true }}
          />
        )),
      }}
      search={{
        inputName: "search",
        onKeyUp: (event) => setSearch(event.currentTarget.value),
        ref,
      }}
      refresh={{ onClick: refresh }}
      clear={{}}
    />
  );
};

export default GMBPageList;
