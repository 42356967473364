import React, { useCallback, useMemo, useState } from "react";
import { Modal } from "@/components/layout";
import { useSearchParams, useNavigate, useLocation } from "react-router-dom";
import { LuRocket } from "react-icons/lu";
import { WorkspaceFormField, WorkspaceNotionItem } from "@/components";
import NotionTableSelect from "./NotionTableSelect";
import { NotionDbType } from "@/store/notion";
import { useStrapi } from "@/providers/StrapiProvider";
import { useWatch } from "react-hook-form";
import {
  NotionDatabaseMapper,
  Workspace,
} from "@/modules/strapi-sdk/lib/interfaces";
import { useRecoilRefresher_UNSTABLE } from "recoil";
import { workspaceById } from "@/store/workspace";
import { collectionListSelector } from "@/store/collection-list";

const labels = {
  notion_account: "Bdd Clients",
  notion_post: "Bdd Livrables",
  notion_notification_cr: "Bdd Notification CR",
  notion_notification_post: "Bdd Retour client",
  norion_user: "Utilisateurs",
};

type Props = {
  workspace?: Workspace;
};

const WorkspaceForm: React.FC<Props> = ({ workspace }) => {
  const location = useLocation();
  const navigate = useNavigate();
  const [searchParams, setSearchParams] = useSearchParams();
  const openModal = useMemo(() => {
    const action = searchParams.get("action");
    if (!action) return false;
    return ["new", "edit"].includes(action);
  }, [searchParams]);

  const [dbType, setDbType] = useState<NotionDbType | undefined>();
  const title = useMemo(
    () => (workspace ? "Mise à jour" : "Nouveau workspace"),
    [workspace]
  );

  const refreshWorkspace = useRecoilRefresher_UNSTABLE(
    workspaceById(workspace?.id)
  );
  const refreshWorkspaces = useRecoilRefresher_UNSTABLE(
    collectionListSelector({ collection: "workspaces", id: "workspaces" })
  );

  const [strapi, { loading }] = useStrapi();

  const onClose = useCallback(() => {
    if (loading) return;
    searchParams.delete("action");
    setSearchParams(searchParams);

    navigate({
      pathname: location.pathname,
      search: searchParams.toString() ? `?${searchParams.toString()}` : "",
    });
  }, [loading, location.pathname, navigate, searchParams, setSearchParams]);

  const onSyncMapper = useCallback(async () => {
    await strapi?.get("notion/sync-mapper");
  }, [strapi]);

  const onSubmit = useCallback(
    async ({ id, ...values }: Partial<Workspace>) => {
      if (loading) return;
      // creation
      if (!id) {
        await strapi?.create("workspaces", values);
        refreshWorkspaces();
        onClose();
        return;
      }
      // update
      await strapi?.update("workspaces", id, values);
      onClose();
      refreshWorkspace();
      refreshWorkspaces();
      console.log("values", values);
    },
    [loading, onClose, refreshWorkspace, refreshWorkspaces, strapi]
  );

  return (
    <Modal
      open={openModal}
      type="form"
      title={title}
      icon={{ icon: LuRocket }}
      width="lg"
      noActions
      onClose={onClose}
      form={{ defaultValues: workspace, onSubmit }}
    >
      <WorkspaceFormField
        back={{ onClick: onClose, disabled: loading }}
        modal={{
          children: (
            <NotionTableSelect
              onClose={() => setDbType(undefined)}
              type={dbType}
            />
          ),
        }}
        syncBtn={{ onClick: onSyncMapper, disabled: loading }}
        tablesConfig={{
          children: <List loading={loading} onClick={setDbType} />,
        }}
      />
    </Modal>
  );
};

type ListProps = {
  loading?: boolean;
  onClick: (value?: NotionDbType) => void;
};

const List: React.FC<ListProps> = (props) => {
  const { loading, onClick } = props;

  const workspace = useWatch() as Partial<Workspace>;
  const items = useMemo(() => {
    const values = (
      [
        "notion_account",
        "notion_post",
        "notion_notification_cr",
        "notion_notification_post",
        "norion_user",
      ] as NotionDbType[]
    ).reduce((acc, curr) => {
      acc[curr] = { ...workspace[curr], label: labels[curr] };
      return acc;
    }, {} as Record<NotionDbType, NotionDatabaseMapper & { label: string }>);

    return Object.entries(values);
  }, [workspace]);

  const onNavigate = (url: string) => {
    window.open(url, "_blank", "noopener,noreferrer");
  };

  return (
    <>
      {items.map(([type, { label, name, notion_url }]) => (
        <WorkspaceNotionItem
          key={type}
          selectBtn={{
            disabled: loading,
            onClick: () => onClick(type as NotionDbType),
            text: name ? "Modifier" : "Selectionner",
          }}
          icon={{ onClick: () => notion_url && onNavigate(notion_url) }}
          label={{ text: label }}
          name={{ text: name, hidden: !name }}
        />
      ))}
    </>
  );
};

export default WorkspaceForm;
