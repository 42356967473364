import React, {
  Suspense,
  useCallback,
  useEffect,
  useMemo,
  useState,
} from "react";
import { Drawer, Skeleton } from "@mui/material";
import {
  FacebookSkin,
  InstagramSkin,
  LinkedinSkin,
  PreviewPost,
  TiktokSkin,
} from "@/components";
import { useRecoilValue } from "recoil";
import { notionOptionByValue } from "@/store/notion";
import { accountSelector } from "@/store/accounts";
import Carrousel from "./Carrousel";
import Tabs, { Tab } from "./Tabs";
import HashTags from "./HashTags";
import Video from "./Video";
import Comments from "./Comments";
import { PistacheLoader } from "@/components/layout";
import { authState } from "@/store/auth";
import { useRefreshPlanning } from "@/hooks/planning";
import { useStrapi } from "@/providers/StrapiProvider";
import { usePost } from "@/hooks/post";
import { useNavigate } from "@repleek/mui";
import { ErrorMedia } from "@/interfaces";
import ErrorAlert from "./ErrorAlert";
import { toast } from "react-toastify";
import { useI18n } from "@/hooks/i18n";

type Props = {
  id: string;
  postId?: string;
  onClose: () => void;
  onRemovePost: (id: number) => void;
};

type Media =
  | {
      type: "carrousel";
      media: (string | undefined)[];
    }
  | {
      type: "video";
      media: {
        url: string;
        background: string;
      };
    };

const PlanningDrawer: React.FC<Props> = (props) => {
  const { id, postId, onRemovePost } = props;

  const [strapi, { loading }] = useStrapi();
  const { navigate } = useNavigate();
  const translate = useI18n();

  const [reloadPlanning, setReloadPlanning] = useState(false);
  const [post, { setPost }] = usePost({
    accountId: id,
    id: postId,
    onError: () => navigate?.(`/account/${id}/planning`),
  });

  const option = useRecoilValue(
    notionOptionByValue({
      key: post?.status,
      collection: "livrables",
    })
  );
  const account = useRecoilValue(accountSelector(id));
  const user = useRecoilValue(authState);

  const refreshPlanning = useRefreshPlanning();

  const socials = useMemo(() => {
    const socials: any[] = [];
    if (post?.socialProfileIs) {
      const { facebook, instagram, linkedin, tiktok } = post.socialProfileIs;
      if (facebook) socials.push("facebook");
      if (instagram) socials.push("instagram");
      if (linkedin) socials.push("linkedin");
      if (tiktok) socials.push("tiktok");
    }
    return socials;
  }, [post?.socialProfileIs]);

  const media = useMemo<Media | undefined>(() => {
    if (post?.video?.video?.url && post.video.background?.url) {
      return {
        type: "video",
        media: {
          url: post.video.video.url,
          background: post.video.background?.url,
        },
      };
    }
    if (post?.media?.length) {
      if (post.media.length) {
        return {
          type: "carrousel",
          media: post.media.map(({ url }) => url) as string[],
        };
      }
    }
    return undefined;
  }, [post]);

  const errorMedia = useMemo(() => {
    const errors: Record<string, string[]> = {};
    if (post?.errors?.type) {
      ["facebook", "instagram", "tiktok", "linkedin"].forEach((key) => {
        errors[key] = [translate(post.errors?.type as string)];
      });
    }
    if (post?.errors?.media)
      Object.entries(post.errors.media as ErrorMedia).forEach(
        ([key, value]) => {
          if (value?.errors?.length) {
            if (errors[key]) errors[key].push(...value.errors);
            else errors[key] = value.errors.map((error) => translate(error));
          }
        }
      );

    return errors;
  }, [post?.errors?.media, post?.errors?.type, translate]);

  const sucess = useMemo(() => {
    const success: Record<string, boolean> = {};
    if (post?.hootsuite_messages?.length) {
      post.hootsuite_messages.forEach(({ profileType, state }) => {
        if (profileType && state)
          success[profileType] = ["SCHEDULED", "SENT"].includes(state);
      });
    }

    Object.keys(errorMedia).forEach((key) => {
      success[key] = false;
    });

    return success;
  }, [errorMedia, post?.hootsuite_messages]);

  const [tab, setTab] = useState<Tab | undefined>(socials[0]);
  const activeTab = useMemo(() => tab || socials[0], [socials, tab]) as Tab;

  const config = useMemo(
    () => ({
      avatar: { src: account?.url },
      name: { text: account?.name },
      description: {
        text: post?.text?.replaceAll("\n", "<br/>"),
      },
      tags: {
        children: <HashTags tags={post?.tags || []} tab={activeTab} />,
      },
      media: {
        children:
          media?.type === "carrousel" ? (
            <Carrousel media={media.media} />
          ) : (
            <Video
              url={media?.media.url}
              background={media?.media.background}
            />
          ),
      },
    }),
    [
      account?.name,
      account?.url,
      activeTab,
      media?.media,
      media?.type,
      post?.tags,
      post?.text,
    ]
  );

  const onClose = useCallback(() => {
    if (!reloadPlanning) return props.onClose();
    props.onClose();
    setTimeout(() => {
      refreshPlanning();
      setReloadPlanning(false);
    }, 300);
  }, [props, refreshPlanning, reloadPlanning]);

  const onOpenNotionLink = useCallback(async () => {
    if (!post?.id) return;
    const isValid = await strapi?.get("posts/notion/:id", { ":id": post.id });
    // close modal
    props.onClose();
    if (!isValid) {
      // remove from calendar
      onRemovePost(post.id);
    } else {
      window.open(post?.notion_url, "_blank");
      refreshPlanning();
    }
  }, [
    onRemovePost,
    post?.id,
    post?.notion_url,
    props,
    refreshPlanning,
    strapi,
  ]);

  const onSchedulePost = useCallback(async () => {
    if (!post?.id || !account?.workspace?.id) return;
    const res = await strapi?.get(":wkId/posts/:id/schedule", {
      ":id": post.id,
      ":wkId": account.workspace.id,
    });
    if (res?.id) {
      setPost(res);
      refreshPlanning();
      if (res.errors?.type) toast.error(translate(res.errors.type));
    }
  }, [
    account?.workspace?.id,
    post?.id,
    setPost,
    strapi,
    refreshPlanning,
    translate,
  ]);

  useEffect(() => {
    if (!post?.id) setTab(undefined);
  }, [post?.id]);

  return (
    <Drawer
      open={!!post}
      anchor="right"
      onClose={onClose}
      PaperProps={{
        sx: {
          height: "calc(100% - 16px)",
          top: "8px",
          right: "12px",
          borderRadius: "18px",
        },
      }}
    >
      <PreviewPost
        name={{ text: post?.title }}
        socialTab={{
          children: (
            <Tabs
              socials={socials}
              tab={activeTab}
              onChange={(tab) => setTab(tab)}
              success={sucess}
            />
          ),
        }}
        body={{
          children: (
            <>
              <ErrorAlert errors={errorMedia} activeTab={activeTab} />
              {activeTab === "facebook" && <FacebookSkin {...config} />}
              {activeTab === "instagram" && <InstagramSkin {...config} />}
              {activeTab === "linkedin" && <LinkedinSkin {...config} />}
              {activeTab === "tiktok" && <TiktokSkin {...config} />}
              {activeTab === "comments" && post?.id && (
                <Suspense
                  fallback={<PistacheLoader css={{ height: "450px" }} />}
                >
                  <Comments
                    postId={post.id}
                    setReloadPlanning={setReloadPlanning}
                  />
                </Suspense>
              )}
            </>
          ),
        }}
        status={{
          className: option?.color,
          color: "info",
          variant: "outlined",
          label: option?.label,
          hidden: !option,
        }}
        notion={{
          onClick: onOpenNotionLink,
          hidden: user?.type === "customer" || !post?.notion_url || loading,
        }}
        hootsuite={{
          onClick: onSchedulePost,
          hidden:
            user?.type === "customer" ||
            loading ||
            !["to-publish", "validated"].includes(post?.status || ""),
        }}
        skeleton={{
          hidden: !loading,
          children: (
            <>
              <Skeleton width={38} height={38} variant="circular" />
              <Skeleton width={38} height={38} variant="circular" />
            </>
          ),
        }}
        close={{ onClick: onClose }}
      />
    </Drawer>
  );
};

export default PlanningDrawer;
