import React from "react";
import { Controller, useFormContext } from "react-hook-form";
import { MobileDatePicker } from "@mui/x-date-pickers";

type Props = {
  name: string;
  label: string;
  disablePast?: boolean;
};

const DatePicker: React.FC<Props> = (props) => {
  const { name, label, ...rest } = props;
  const { control } = useFormContext();

  return (
    <Controller
      control={control}
      name={name}
      rules={{ required: `${label}is required` }}
      render={({
        field: { ref, onBlur, name, onChange, value = null, ...field },
        fieldState: { error },
      }) => (
        <>
          <MobileDatePicker
            inputRef={ref}
            label={label}
            value={value}
            onChange={onChange}
            slotProps={{
              calendarHeader: {
                slotProps: {
                  nextIconButton: {
                    color: "secondary",
                    className: "no-shadow no-borders",
                  },
                  previousIconButton: {
                    color: "secondary",
                    className: "no-shadow no-borders",
                  },
                  switchViewButton: {
                    color: "secondary",
                    className: "no-shadow no-borders",
                  },
                },
              },
              textField: {
                size: "small",
                fullWidth: true,
                required: true,
                error: !!error,
                helperText: error?.message || "",
              },
            }}
            localeText={{ cancelButtonLabel: "Annuler" }}
            format="DD/MM/YYYY"
            {...field}
            {...rest}
          />
        </>
      )}
    />
  );
};

export default DatePicker;
