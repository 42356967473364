import CollectionList from "@/components/layout/collections";
import { Workspace } from "@/modules/strapi-sdk/lib/interfaces";
import { useNavigate } from "@repleek/mui";
import React from "react";

type Props = {
  workspace: Workspace;
};

const Accounts: React.FC<Props> = (props) => {
  const { workspace } = props;
  const { navigate } = useNavigate();

  return (
    <CollectionList
      collection={"me/accounts"}
      id="workspace-accounts"
      filters={[]}
      defaultFilters={{ workspaces: [workspace.id as number] }}
      deleteItem
      onCreate={() => navigate?.("/new-account")}
      onUpdate={(id) => navigate?.(`/edit-account/${id}`)}
      onOpen={(id) => navigate?.(`/account/${id}`)}
    />
  );
};

export default Accounts;
