import React, { useCallback, useMemo, useState } from "react";
import CollectionList from "@/components/layout/collections";
import { userTypeOptions } from "@/store/user";
import { Workspace } from "@/modules/strapi-sdk/lib/interfaces";
import { IoMdPaperPlane } from "react-icons/io";
import UserModal from "@/components/features/user/UserModal";
import { useLocation, useNavigate, useSearchParams } from "react-router-dom";
import { useSetRecoilState } from "recoil";
import { collectionQuery } from "@/store/collection-list";
import { EmailModal } from "@/components/features/notificationCenter";
import { useStrapi } from "@/providers/StrapiProvider";

type Props = { workspace: Workspace };

const Users: React.FC<Props> = (props) => {
  const { workspace } = props;
  const location = useLocation();
  const navigate = useNavigate();
  const [searchParams, setSearchParams] = useSearchParams();
  const [strapi, { loading }] = useStrapi();

  const setQuery = useSetRecoilState(
    collectionQuery({ collection: "me/users", id: "workspace-users" })
  );

  const [notification, setNotification] = useState<
    { id: number; email?: string; resetToken?: string } | undefined
  >(undefined);

  const userId = useMemo(() => {
    const action = searchParams.get("action");
    if (!action) return false;
    if (action === "new-user") return true;
    if (parseInt(action) > 0) return parseInt(action);
    return false;
  }, [searchParams]);

  const onClose = useCallback(() => {
    searchParams.delete("action");
    setSearchParams(searchParams);

    navigate({
      pathname: location.pathname,
      search: searchParams.toString() ? `?${searchParams.toString()}` : "",
    });
  }, [location.pathname, navigate, searchParams, setSearchParams]);

  const refresh = useCallback(() => {
    setQuery((q) => ({ ...q }));
  }, [setQuery]);

  const onSendNotification = useCallback(
    async (id: number) => {
      if (!id) return undefined;
      setNotification({ id });
      const user = await strapi?.findOne("me/users", id);
      if (user) {
        const { email } = user;
        setNotification({ id, email });
        const resetToken = await strapi?.get("/auth/:email/reset-token", {
          ":email": email,
        });
        if (resetToken) setNotification({ id, email, resetToken });
        else setNotification(undefined);
      }
    },
    [strapi]
  );

  return (
    <>
      <CollectionList
        collection={"me/users"}
        id="workspace-users"
        filters={[
          {
            label: "Role",
            name: "type",
            type: "tag",
            options: userTypeOptions,
          },
        ]}
        defaultFilters={{ workspaces: [workspace.id as number] }}
        deleteItem
        onCreate={() =>
          navigate?.(`/workspaces/${workspace.id}/users?action=new-user`)
        }
        onOpen={(id) =>
          navigate?.(`/workspaces/${workspace.id}/users?action=${id}`)
        }
        onUpdate={onSendNotification}
        actions={{
          openBtn: { text: "Modifier" },
          editBtn: { Icon: <IoMdPaperPlane /> },
        }}
      />

      <EmailModal
        open={!!notification}
        loading={loading || !notification?.email}
        models={["resetPassword", "DEFAULT"]}
        content={{
          resetPassword: {
            cta: {
              label: "Réinitialiser mon mot de passe",
              link: `https://dashboard.agence-pistache.fr/reset-password?token=${notification?.resetToken}`,
            },
          },
        }}
        email={notification?.email}
        onClose={() => setNotification(undefined)}
      />

      <UserModal
        open={!!userId}
        id={typeof userId === "number" ? userId : undefined}
        wkId={workspace.id}
        onClose={onClose}
        onSave={refresh}
      />
    </>
  );
};

export default Users;
