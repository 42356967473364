import React, { Suspense, useMemo } from "react";
import Layout, { PistacheLoader } from "@/components/layout";
import CollectionList from "@/components/layout/collections";
import { useNavigate } from "@repleek/mui";
import { useRecoilValue } from "recoil";
import { myWorkspaces } from "@/store/workspace";

const AccountsPage: React.FC = () => {
  return (
    <Layout title="Pistache - comptes" mode="ADMIN">
      <Suspense fallback={<PistacheLoader />}>
        <Component />
      </Suspense>
    </Layout>
  );
};

const Component: React.FC = () => {
  const { navigate } = useNavigate();

  const workspaces = useRecoilValue(myWorkspaces);
  const wkOptions = useMemo(
    () =>
      workspaces?.map(({ id, name }) => ({
        label: name as string,
        value: id as number,
      })) || [],
    [workspaces]
  );

  return (
    <CollectionList
      collection={"me/accounts"}
      id="accounts"
      filters={[
        {
          label: "Type de Compte",
          name: "type",
          type: "tag",
          options: [
            { label: "Classique", value: "classic" },
            { label: "Restaurant", value: "restaurant" },
          ],
        },
        {
          label: "Workspaces",
          name: "workspaces",
          type: "tag",
          options: wkOptions,
        },
        {
          label: "Leads",
          name: "leads",
          type: "toggle",
          options: [
            { label: "Oui", value: true },
            { label: "Non", value: false },
          ],
        },
      ]}
      deleteItem
      onCreate={() => navigate?.("/new-account")}
      onUpdate={(id) => navigate?.(`/edit-account/${id}`)}
      onOpen={(id) => navigate?.(`/account/${id}`)}
    />
  );
};

export default AccountsPage;
