import React, { Suspense, useCallback } from "react";
import { Box } from "@mui/material";
import { Modal, PistacheLoader } from "@/components/layout";
import GMBPageList from "./UserModalList";

type Props = {
  selectedUsers: number[];
  open: boolean;
  onClose: () => void;
  onChange: (userId: number) => void;
};

const UserSelectModal: React.FC<Props> = (props) => {
  const { onClose, selectedUsers, open, onChange } = props;

  const onLink = useCallback(
    async (userId?: number) => {
      if (userId) {
        onChange(userId);
      }
    },
    [onChange]
  );

  return (
    <Modal open={open} onClose={onClose} noHeader type="box" noActions>
      <Suspense
        fallback={
          <Box sx={{ minWidth: "340px" }}>
            <PistacheLoader />
          </Box>
        }>
        <GMBPageList onLink={onLink} selectedUsers={selectedUsers} />
      </Suspense>
    </Modal>
  );
};

export default UserSelectModal;
